import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="flex h-[88vh] w-full flex-col items-center justify-center gap-8">
      <img src="/images/pagenotFound.svg" alt="notfound" />
      <Link to="/">
        {" "}
        <button className="rounded-lg bg-lightBlue px-8 py-4 font-outFitMedium text-white">
          Back to Home
        </button>
      </Link>
    </div>
  );
};

export default PageNotFound;
