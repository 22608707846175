import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  products: [],
  sub_categories: [],
};

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setSubCategories: (state, action) => {
      state.sub_categories = action?.payload?.category_data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProducts, setSubCategories } = productSlice.actions;

export default productSlice.reducer;
