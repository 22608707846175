import React from "react";
import { useState } from "react";
import Validator from "validatorjs";
import CustomButton from "../common/Button";
import TextArea from "./../common/Textarea";
import Backdrop from "@mui/material/Backdrop";
import { InputBox } from "../common/TextField";
import axiosInstance from "../../helpers/axios";
import TelephoneInput from "../common/TelephoneInput";
import { Box, CircularProgress, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // maxWidth: 600,
  // minWidth: 360,
  bgcolor: "#fcfcfc",
  boxShadow: 24,
};

const EnquiryModal = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const fields = {
    name: "",
    phone: "",
    email: "",
    message: "",
    lead_type: "ENQUIRY",
  };
  const [params, setParams] = useState(fields);
  const [errors, setErrors] = useState(fields);

  const handleChange = (e) => {
    setErrors("");
    const { name, value } = e.target;
    setParams({ ...params, [name]: value });
  };

  const validate = (parameters, rule) => {
    const validator = new Validator(parameters, rule);
    if (validator.fails()) {
      const fieldErrors = {};

      /* eslint-disable */
      for (const key in validator.errors.errors) {
        fieldErrors[key] = validator.errors.errors[key][0];
      }
      /* eslint-enable */
      setErrors(fieldErrors);
      return false;
    }
    setErrors({});
    return true;
  };

  const handleSubmit = (e) => {
    let rules = {
      name: "required|max:50",
      email: "required|email",
      phone: "required",
      // "phone.number": "required",
    };
    if (!validate(params, rules)) {
      const err = Object.keys(errors);
      if (err?.length) {
        const input = document.querySelector(`input[name=${err[0]}]`);
      }

      return;
    }

    //api call goes here

    let postData = {
      name: params?.name,
      email: params?.email,
      message: params?.message,
      phone: params.phone,
      lead_type: params?.lead_type,
    };

    setLoading(true);

    axiosInstance
      .post("/lead", postData)
      .then((res) => {
        setLoading(false);
        setParams(fields);
        setSubmitted(true);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleCloseButton = () => {
    setErrors({ phone: "" });
    handleClose();
  };

  const onPhoneNumberChange = (isValid, value, selectedCountryData) => {
    // setParams({ ...params, phone: value, nationality: selectedCountryData.name })
    setParams({ ...params, phone: value });

    if (!isValid) {
      setErrors({ ...errors, phone: "Enter valid phone number" });
    } else {
      setErrors({ ...errors, phone: "" });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseButton}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className="font-outFitRegular"
    >
      <Box
        sx={style}
        style={{
          textAlign: "",
          borderRadius: "8px",
          outline: "none",
        }}
      >
        <div className="flex w-[320px] flex-col gap-4 p-7 lg:w-[496px] lg:px-[58px] lg:pb-[52px] lg:pt-[38px]">
          <div
            onClick={handleCloseButton}
            className="absolute right-7 cursor-pointer lg:right-[58px]"
          >
            {closeicon}
          </div>
          <p className="font-outfitMedium text-2xl lg:text-[28px]">
            Enquire Now
          </p>
          <p className="font-outfitRegular text-base lg:text-lg">
            Please complete this form to be contacted by one of our experts.
          </p>
          <InputBox
            rows={1}
            width="w-full"
            handleChange={handleChange}
            value={params.name}
            label="Your Name"
            name="name"
            error={!!errors.name}
            helperText={errors.name}
          />
          <InputBox
            rows={1}
            width="w-full"
            handleChange={handleChange}
            value={params.email}
            label="Email Id"
            name="email"
            error={!!errors.email}
            helperText={errors.email}
          />

          <div className="">
            <TelephoneInput
              onPhoneNumberChange={onPhoneNumberChange}
              params={params}
              errors={errors}
            />

            <p className="font-satoshiMedium relative left-[15px] top-[5px] text-xs text-red ">
              {errors?.phone}
            </p>
          </div>

          <TextArea
            placeholder="Write a message to us"
            name="message"
            rows={4}
            handleChange={handleChange}
            value={params.message}
            error={errors?.message}
            helperText={errors?.message}
            className="border border-[#E7E8ED] hover:border-black"
            readOnly={false}
          />

          <br />
          <p className="body4 text-oliveGreen">
            {submitted
              ? "Thank you, your response is Submitted. We will get back to you soon ! "
              : ""}
          </p>

          <CustomButton
            onClick={handleSubmit}
            variant="contained"
            size="large"
            height="56px"
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center justify-center gap-2">
                <CircularProgress size="2vh" sx={{ color: "black" }} />
                <p>Submit</p>
              </span>
            ) : (
              "Submit"
            )}
          </CustomButton>
        </div>
      </Box>
    </Modal>
  );
};

export default EnquiryModal;
var closeicon = (
  <svg
    width="32"
    height="31"
    viewBox="0 0 32 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7905 11.917L12.2031 19.3674"
      stroke="#222222"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.789 19.3722L12.1953 11.9141"
      stroke="#222222"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.8596 1.27539H9.13365C4.3504 1.27539 1.35156 4.60101 1.35156 9.30724V22.0065C1.35156 26.7127 4.33615 30.0383 9.13365 30.0383H22.858C27.6571 30.0383 30.6432 26.7127 30.6432 22.0065V9.30724C30.6432 4.60101 27.6571 1.27539 22.8596 1.27539Z"
      stroke="#222222"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
