import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import MainListing from "./MainListing";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../helpers/axios";
import { setSubCategories } from "../features/products/productSlice";
import updateMeta from "../helpers/seo-meta";

const herodata = [
  {
    banner:
      "/videos/bedroom.mp4",
    poster: '/images/posters/bedroom.jpg',
    id: 1,
    title: "BEDDING",
    slug: "bedding",
    description: "Indulge in luxury with our plush bathroom essentials.",
  },
  {
    banner: "/videos/bath.mp4",
    poster: '/images/posters/bath.jpg',
    id: 2,
    title: "BATH",
    slug: "bath",
    description: "Indulge in luxury with our plush bathroom essentials.",
  },
  {
    banner: "/videos/window.mp4",
    poster: '/images/posters/window.jpg',
    id: 3,
    title: "WINDOW",
    slug: "window",
    description:
      "Discover curtains to elevate your space with style and light control.",
  },
  {
    banner: "/videos/decor.mp4",
    poster: '/images/posters/decor.jpg',
    id: 4,
    title: "DECOR",
    slug: "decor",
    description: "Enhance your space with stylish decor accents. ",
  },
  {
    banner:
      "/videos/clothing.mp4",
    poster: '/images/posters/clothing.jpg',
    id: 5,
    title: "CLOTHING",
    slug: "clothing",
    description:
      "Experience premium clothing that blends comfort and style flawlessly. ",
  },
];

const Products = () => {
  const { category, subcategory } = useParams();

  const getbyIdHeroData = herodata.find((item) => item.slug === category);

  const { sub_categories } = useSelector((state) => state.products);

  const tmpVal = sub_categories.find((e) => e?.slug === subcategory);

  const [prodList, setProdList] = useState([]);

  const [filterslist, setFiltersList] = useState([]);

  const [filterIds, setFilterIds] = useState([]);

  const [listloading, setListLoading] = useState(false);
  const [catloading, setCatLoading] = useState(false);

  const [activeCat, setActiveCat] = useState({
    slug: "",
    name: "All Products",
    image: "/images/all_products.svg",
  });

  const handleFilter = (id) => {
    const list = [...filterslist];

    list.map(({ child }) => {
      return child.forEach((element) => {
        if (element?.filter_id === id) {
          if (element["is_active"]) {
            element["is_active"] = false;
          } else {
            element["is_active"] = true;
          }
        }
      });
    });

    setFiltersList(list);
  };

  const dispatch = useDispatch();

  useMemo(() => {
    const activeIds = [];


    for (const item of filterslist) {
      for (const childItem of item.child) {
        if (childItem.is_active) {
          activeIds.push(childItem?.filter_id);
        }
      }
    }

    setFilterIds(activeIds);
  }, [filterslist]);

  const GetSubCategoriesWithFilters = () => {
    setCatLoading(true);
    axiosInstance
      .get(`/products/prerequisite/${category}`)
      .then((res) => {
        dispatch(setSubCategories(res.data));

        const list = [...res.data.filter_data];

        list.map(({ child }) => {
          return child.forEach((element) => {
            element["is_active"] = false;
          });
        });

        setFiltersList(list);
        setCatLoading(false);
      })
      .catch((error) => {
        setCatLoading(false);
      });
  };

  const getProdListByCatId = (emtpyids) => {
    setListLoading(true);

    const slug = activeCat?.slug === undefined ? "" : activeCat?.slug;

    axiosInstance
      .get(
        `/products/list/${category}${"/" + slug}?filters=${emtpyids ? emtpyids : filterIds.toString()
        }`
      )
      .then((res) => {
        setListLoading(false);
        setProdList(res?.data?.data);

        const list = [...res?.data?.filter_data];

        list.map(({ child }) => {
          return child.forEach((element) => {
            element["is_active"] = false;
          });
        });


        setFiltersList(list);

      })
      .catch((error) => {
        setListLoading(false);
      });
  };

  useEffect(() => {
    getProdListByCatId();
  }, [category, activeCat?.id]);

  const ApplyFilter = () => {
    getProdListByCatId();
  };
  const ResetFilter = () => {
    const list = [...filterslist];
    for (const item of filterslist) {
      for (const childItem of item.child) {
        if (childItem.is_active) {
          childItem["is_active"] = false;
        }
      }
    }
    setFiltersList(list);
    getProdListByCatId([]);
  };

  useMemo(() => {
    setActiveCat(tmpVal);
  }, [tmpVal]);

  useEffect(() => { }, [category]);

  useEffect(() => {
    GetSubCategoriesWithFilters();
  }, [category]);
  useEffect(() => {
    document.title = "Products | DuvetHome ";
    updateMeta(
      "description",
      "Duvet Ltd offers premium bedding products for a comfortable and luxurious sleep experience. Shop now for high-quality duvets, pillows, and more."
    );
  }, []);
  return (
    <>
      {" "}
      <MainListing
        filterIds={filterIds}
        ApplyFilter={ApplyFilter}
        ResetFilter={ResetFilter}
        catloading={catloading}
        listloading={listloading}
        getbyIdHeroData={getbyIdHeroData}
        handleFilter={handleFilter}
        sub_categories={sub_categories}
        filterslist={filterslist}
        prodList={prodList}
        activeCat={activeCat}
        setActiveCat={setActiveCat}
        subcategory={subcategory}
        category={category}
      />
    </>
  );
};

export default Products;
