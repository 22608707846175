import React, { useEffect } from "react";
import gsap from "gsap/dist/gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";

const ScrollToTopButton = () => {
  const scrollToTop = () => {
    typeof window !== "undefined" &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  };

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.fromTo(
      ".scroll_to_top_btn",
      {
        opacity: 0,
      },
      {
        opacity: 0.5,
        scrollTrigger: {
          trigger: ".scroll_to_top_btn",
          start: "top top",
          end: "bottom top",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div
      onClick={scrollToTop}
      className="scroll_to_top_btn fixed bottom-24 right-8  z-[100] flex h-16 w-16 animate-bounce cursor-pointer items-center justify-center rounded-full bg-Gainsboro  sm:hidden "
    >
      <img src="/images/icons/backtop.svg" alt="back_to_top" className="w-[27px] h-[28px]" />
    </div>
  );
};

export default ScrollToTopButton;
