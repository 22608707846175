import React, { useEffect } from "react";
import CustomButton from "../components/common/Button";
import gsap from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import SubHeading from "../components/SubHeading";
import WhatWeDoMobile from "../components/WhatWeDoMobile";
import { Link } from "react-router-dom";
import updateMeta from "../helpers/seo-meta";

const connection_prod_styles = [
  {
    count: 1200,
    label: "Connections",
    image: "/images/aboutus/connections.png",
  },
  {
    count: 450,
    label: "Products",
    image: "/images/aboutus/products.png",
  },
  {
    count: 382,
    label: "Styles",
    image: "/images/aboutus/styles.png",
  },
];

const About = () => {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    const tl = gsap.timeline();
    let screensize = "mobile";
    // 1.box1 timeline
    tl.to(".purpose1", {
      x: screensize === "xlarge" ? -600 : -430,
      y: screensize === "xlarge" ? -400 : -200,
      scrollTrigger: {
        trigger: ".purpose-section",
        start: "top bottom",
        end: "45% 65%",
        id: "enter",
        scrub: 2,
      },
    });

    // 2.box2 timeline
    tl.to(".purpose2", {
      x: screensize === "xlarge" ? -200 : -220,
      y: screensize === "xlarge" ? -400 : -200,
      scrollTrigger: {
        trigger: ".purpose-section",
        start: "top bottom",
        end: "45% 65%",
        // markers: true,
        id: "enter",
        scrub: 2,
      },
    });

    //3. box3 timeline

    tl.to(".purpose3", {
      x: screensize === "xlarge" ? 200 : 0,
      y: screensize === "xlarge" ? -400 : -200,
      scrollTrigger: {
        trigger: ".purpose-section",
        start: "top bottom",
        end: "45% 65%",
        id: "enter",
        scrub: 2,
      },
    });

    // 4.box4 timeline
    tl.to(".purpose4", {
      x: screensize === "xlarge" ? 600 : 430,
      y: screensize === "xlarge" ? -400 : -200,
      scrollTrigger: {
        trigger: ".purpose-section",
        start: "top bottom",
        end: "45% 65%",
        id: "enter",
        scrub: 2,
      },
    });
    //5. box5 timeline
    tl.to(".purpose5", {
      x: screensize === "xlarge" ? -600 : -430,
      scrollTrigger: {
        trigger: ".purpose-section",
        start: "top bottom",
        end: "45% 65%",
        id: "enter",
        scrub: 2,
      },
    });

    // // 6.box7 timeline
    tl.to(".purpose7", {
      x: screensize === "xlarge" ? 600 : 430,

      scrollTrigger: {
        trigger: ".purpose-section",
        start: "top bottom",
        end: "45% 65%",
        id: "enter",
        scrub: 2,
      },
    });

    // purpose8
    tl.to(".purpose8", {
      x: screensize === "xlarge" ? 200 : 220,
      y: screensize === "xlarge" ? -400 : -200,
      scrollTrigger: {
        trigger: ".purpose-section",
        start: "top bottom",
        end: "45% 65%",
        id: "enter",
        scrub: 2,
      },
    });

    // 7.timeline for centered box
    tl.to(".centered-purpose", {
      width: "40%",
      scrollTrigger: {
        trigger: ".purpose-section",
        start: "top bottom",
        end: "45% 65%",
        id: "enter",
        scrub: 2,
      },
    });

    // 8.text inside center box fade animation
    gsap.to(".centered-purpose p", {
      opacity: 1,
      scrollTrigger: {
        trigger: ".purpose-section",
        start: "top bottom",
        end: "45% 65%",
        scrub: 2,
      },
    });
  }, []);

  useEffect(() => {
    document.title = "About Us | DuvetHome ";
    updateMeta(
      "description",
      "Duvet Ltd offers premium bedding products for a comfortable and luxurious sleep experience. Shop now for high-quality duvets, pillows, and more."
    );
  }, []);
  return (
    <>
      <div className="px-1">
        {/* hero section */}

        <section className="m-auto   flex h-[90vh] w-full flex-col   items-center justify-center  gap-y-0 bg-aboutus_hero  bg-cover  bg-center bg-no-repeat p-8  px-1 sm:gap-y-6 sm:bg-aboutus_hero  md:h-[100vh]">
          <div className="relative h-full w-full">
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <div className="w-full text-white sm:w-1/2">
                <h1 className="px-4 text-center text-xl md:text-4xl ">
                  Discover The Story of Duvet Home
                </h1>
                <br />
                <p className="px-8 text-center font-outFitRegular text-base md:text-base ">
                  Embark on the captivating journey of Duvet Homes, where
                  creativity, style, and trust unite to elevate your space.
                </p>
              </div>
            </div>
          </div>
        </section>

        <br />

        {/* products Cards  */}

        <section className="m-auto w-full px-5 md:w-11/12 md:px-0 lg:w-11/12 lg:px-0 ">
          <div className="flex flex-col items-center justify-between gap-5 sm:flex-row sm:p-10">
            {connection_prod_styles.map((e) => {
              return (
                <div className="flex w-full items-center  justify-between rounded-[12px] p-5 shadow-3xl sm:w-[30%]">
                  <div className="flex flex-col">
                    <p className="title3 text-[#3A5A40]">{e?.count}</p>
                    {/* <CountUp end={e.count} /> */}

                    <p className="title4">{e?.label}</p>
                  </div>
                  <div>
                    <img
                      className="h-[134px] w-[182px] rounded-[12px]"
                      src={e?.image}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </section>

        {/* About us card */}

        <section className="m-auto w-full px-5 md:-mt-5 md:w-11/12 md:px-0 lg:-mt-5 lg:w-11/12 lg:px-0">
          <div className="my-10 flex flex-col sm:flex-row sm:p-10">
            <SubHeading styles="block sm:hidden" text="About Us" />
            <h3 className="block py-2 sm:hidden">
              Crafting Home Elegance Worldwide
            </h3>
            <div className="flex  w-full justify-center sm:w-1/2">
              <img
                className="w-full  sm:w-4/5"
                src="/images/aboutus/about_us_bed.png"
              />
            </div>
            <div className="flex flex-col gap-y-3 pr-8 sm:w-1/2 ">
              <SubHeading styles="sm:block hidden " text="About Us" />
              <h3 className="hidden py-2 sm:block ">
                Crafting Home Elegance Worldwide
              </h3>
              <p className="body4 py-2 leading-[26px] text-[#767676]">
                Duvet Home, Leading manufacturer and exporter of stylish home
                textile and garment products worldwide. Whether you seek
                designer brands or private label collections, we offer a diverse
                range of items and unparalleled creativity. Our dedicated team
                ensures satisfaction tailored to your needs, fostering strong
                buyer relationships through innovation and growth.
              </p>
              <Link to="/">
                <CustomButton
                  height="49px"
                  borderRadius="0.5rem"
                  variant="contained"
                  width={"w-fit"}
                  size="large"
                >
                  <p className="text-white">Explore Products</p>
                </CustomButton>
              </Link>
            </div>
          </div>
        </section>

        {/* What we do section */}
        <div className="mt-4  lg:mt-[46px]">
          <SubHeading styles="mx-auto" text="What We Do" />
          <h5 className="py-2 text-center">Weave comfort and style together</h5>
        </div>

        {/* What we do aNimation */}

        <section className="purpose-section relative hidden h-[439px] py-28 sm:block">
          <div className="purpose1 3xl:bottom-44 res-height absolute bottom-10 left-0 right-0 z-[40] m-auto mx-auto   flex   flex-col  items-center justify-center  gap-y-2 rounded-3xl      bg-[#222222]  p-7 ">
            <img src="/images/designing.svg" alt="Design" />
            <span className="body5 p-3 text-white">Design</span>
          </div>
          <div className="purpose2 3xl:bottom-44 res-height purpose2  3xl:bottom-44 res-height absolute bottom-10 left-0  right-0 z-[30] m-auto mx-auto flex  flex-col items-center  justify-center gap-y-2 rounded-3xl   bg-[#222222] p-4 ">
            <img src="/images/delivery.svg" alt="Logistics" />
            <span className="body5 p-3 text-white">Logistics</span>
          </div>

          <div className="purpose3 3xl:bottom-44   res-height absolute bottom-10 left-0  right-0 z-[30] m-auto  flex  flex-col items-center  justify-center gap-y-2 rounded-3xl   bg-[#222222] p-4  ">
            <img src="/images/marketing.svg" alt="Marketing" />
            <span className="body5 p-3 text-white">Marketing</span>
          </div>

          <div className="purpose4 3xl:bottom-44 res-height   absolute bottom-10 left-0 right-0 z-[10] m-auto flex  flex-col  items-center justify-center gap-y-2  rounded-3xl  bg-[#222222] p-4  ">
            <img src="/images/sales.svg" alt="Sales" />
            <span className="body5 p-3 text-white">Sales</span>
          </div>
          <div className="purpose5 3xl:bottom-44 res-height   absolute bottom-10 left-0 right-0 z-[50] m-auto flex  flex-col  items-center justify-center gap-y-2  rounded-3xl  bg-[#222222] p-4  ">
            <img src="/images/sourcing.svg" alt="delivery" />
            <span className="body5 p-3 text-white">Sourcing</span>
          </div>
          <div className="purpose8 3xl:bottom-44 res-height   absolute bottom-10 left-0 right-0 z-[50] m-auto flex  flex-col  items-center justify-center gap-y-2  rounded-3xl  bg-[#222222] p-4  ">
            <img src="/images/merchandising.svg" alt="Merchandising" />
            <span className="body5 p-3 text-white">Merchandising</span>
          </div>

          <div className="bg-purpose_bg bg-lightgray centered-purpose 3xl:bottom-44 absolute  bottom-10 left-0 right-0 z-[70] m-auto flex h-[160px] w-fit items-center justify-center  rounded-3xl   bg-no-repeat p-4  shadow-3xl">
            <h2 className="text-center ">DUVET HOME</h2>
          </div>

          <div className="purpose7 3xl:bottom-44 res-height  3xl:bottom-44   absolute bottom-10 left-0 right-0 z-[50] m-auto mx-auto   flex  flex-col items-center justify-center gap-y-2 rounded-3xl border bg-[#222222] p-4  ">
            <img src="/images/prod_dev.svg" alt="Product development" />
            <span className="body5 p-3 text-center text-white">
              Product development
            </span>
          </div>
        </section>


        <WhatWeDoMobile />
        {/* What we give section */}

        <section className="m-auto mb-10 mt-5 w-full px-5 md:w-10/12 md:px-0 lg:mb-20 lg:mt-[66px] lg:w-10/12 lg:px-0">
          <SubHeading text="What We Give" />

          <div className="flex flex-col-reverse justify-between gap-4 sm:flex-row">
            <div className="flex w-full flex-col gap-8 sm:w-1/2">
              <h3 className="hidden py-2 md:block lg:block">
                Elevating your lifestyle, the Duvet way: Quality, Style,
                Satisfaction.
              </h3>

              {[
                {
                  title: "Style: Ahead-of-trend designs",
                  content:
                    "Our products are crafted with precision and care, ensuring premium quality.",
                },
                {
                  title: "Satisfaction: Your happiness",
                  content:
                    "Stay ahead of trends with our stylish and modern designs.",
                },
                {
                  title: "Quality: Crafted with precision",
                  content:
                    "We prioritise your happiness, ensuring your needs are met with every purchase.",
                },
              ].map(({ title, content }, index) => {
                return (
                  <div className="flex items-start gap-4">
                    <h4 className="font-bold text-oliveGreen">
                      {" "}
                      0{index + 1}.
                    </h4>

                    <div className="lg:w-[400px]">
                      <p className="title1 text-oliveGreen">{title}</p>
                      <p className="body4">{content}</p>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="block md:hidden lg:hidden">
              <h3 className="py-2">
                Elevating your lifestyle, the Duvet way: Quality, Style,
                Satisfaction.
              </h3>

              <img
                className="m-auto w-full  sm:w-1/2"
                src="/images/aboutus/about_us_last.png"
                alt=" what we give image"
              />
            </div>

            <div>
              <img
                className="m-auto hidden h-[400px] w-full  md:block lg:block"
                src="/images/aboutus/about_us_last.png"
                alt=" what we give image"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default About;
