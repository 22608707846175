import React from "react";

const CategoryPicker = ({ index, handleClick, content, is_active }) => {
  return (
    <p
      onClick={handleClick}
      className={`body5 flex h-[35px] w-[110px] cursor-pointer items-center justify-center truncate   ${
        index % 2 !== 0 ? "justify-self-end" : "justify-self-start"
      } rounded-full px-6    ${
        is_active
          ? "border-2 border-lightBlue  text-lightBlue"
          : "border border-Argent font-light text-black"
      }`}
    >
      {content}
    </p>
  );
};

export default CategoryPicker;
