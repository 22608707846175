import React from "react";
import SmallScreenAboutUs from "./SmallScreenAboutUs";
import CategoryCard from "./CategoryCard.jsx";
import Testimonial from "./SmallScreenTestimonials";
import MobileBannerImages from "./MobileBannerImages.jsx";

const SmallScreenHome = () => {
  return (
    <div className="px-1">
      <div className="w-full bg-white">
        <div className="relative h-[92vh] w-full">
          <MobileBannerImages/>
        </div>

        <div className="mt-8 w-full px-4">
          <CategoryCard />
        </div>

        <div className="mt-8 w-full px-4">
          <SmallScreenAboutUs />
        </div>
        <div className="mt-8 w-full px-4">
          <Testimonial />
        </div>
      </div>
    </div>
  );
};

export default SmallScreenHome;
