import React from "react";

const ColorPicker = ({ clr, is_active, handleClick }) => {
  return (
    <div className={` cursor-pointer  `} onClick={handleClick}>
      <div
        className={` w-fit rounded-full  shadow-2xl   ${
          is_active
            ? " border-2 border-oliveGreen bg-white p-[2px]  shadow-2xl"
            : ""
        }`}
      >
        <div
          style={{ backgroundColor: clr }}
          className={` ${
            is_active ? "h-[25px] w-[25px] " : "h-[26px] w-[26px] "
          } rounded-full `}
        ></div>
      </div>
    </div>
  );
};

export default ColorPicker;

// ${  is_active ? "border-2  border-oliveGreen bg-white  shadow-3xl" : ""}
