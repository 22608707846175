import React from "react";
import CustomButton from "./Button";

const FilterButtons = ({ data, ApplyFilter, ResetFilter }) => {
  return (
    <div className="flex items-center justify-center gap-4 mb-4 ">
      <CustomButton
        onClick={ApplyFilter}
        borderRadius="0.5rem"
        variant="contained"
        disabled={data?.length < 1}
        size="large"
      >
        <p className="text-sm text-white font-outFitRegular">Apply Filters</p>
      </CustomButton>
      {data?.length > 0 ? (
        <CustomButton
          onClick={ResetFilter}
          borderRadius="0.5rem"
          variant="outlined"
          size="large"
        >
          <p className="text-sm font-outFitRegular text-lightBlue">Reset</p>
        </CustomButton>
      ) : (
        ""
      )}
    </div>
  );
};

export default FilterButtons;
