import axios from "axios";

// const baseURL = process.env.REACT_APP_BACKEND_URL;
const baseURL = "https://admin-api.duvetltd.com";
const axiosInstance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

axiosInstance.defaults.withCredentials = true;
export default axiosInstance;
