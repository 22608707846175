import Button from "@mui/material/Button";
import React from "react";

const CustomButton = ({
  disabled,
  children,
  onClick,
  variant = "outlined",
  width,
  size,
  icon,
  borderRadius,
  type = "button",
  isdeletebtn,
  height,
}) => {
  const styles = {
    border: `1px solid ${isdeletebtn ? "#EF4949" : "#3E94FF"}`,
    color: `${isdeletebtn ? "#EF4949" : "#3E94FF"}`,
    "&:hover": {
      border: "1px solid #3E94FF",
      backgroundColor: "rgba(20, 28, 76, 0.1);",
    },
    "&:active": {
      background: "rgba(20, 28, 76, 0.1);",
    },
  };
  const styles1 = {
    border: "none",
    color: "white",
    fontWeight: "700",
    background: "linear-gradient(180deg, #3E94FF 0%, #3E94FF 100%)",
    backgroundColor: "linear-gradient(180deg, #3E94FF 0%, #3E94FF 100%)",
    "&:hover": {
      border: "none",
      background: "linear-gradient(180deg, #3E94FF 0%, #3E94FF 100%)",
      backgroundColor: "linear-gradient(180deg, #3E94FF 0%, #3E94FF 100%)",
      opacity: isdeletebtn ? "0.8" : "",
    },
    "&:active": {
      background: "linear-gradient(180deg, #3E94FF 0%, #3E94FF 100%)",
    },
  };

  const disabledStyles = {
    "&:disabled": {
      fontWeight: "700",
      border: "none ",
      color: "#797979",
      backgroundColor: "linear-gradient(180deg, #FF6142 0%, #FF3F8E 100%)",
    },
  };
  const secondayStyles = {
    border: "1px solid #000000",
    color: "linear-gradient(180deg, #FF6142 0%, #FF3F8E 100%)",
    "&:hover": {
      // border: '1px solid #141C4C',
      backgroundColor: "linear-gradient(180deg, #FF6142 0%, #FF3F8E 100%)",
    },
    "&:active": {
      background: "linear-gradient(180deg, #FF6142 0%, #FF3F8E 100%)",
    },
  };
  return (
    <div className={width}>
      <Button
        style={{ borderRadius, textTransform: "none", height: height }}
        fullWidth
        size={size}
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        startIcon={icon}
        className="font-nunitoRegular"
        type={type}
        sx={
          variant === "secondary" && !disabled
            ? secondayStyles
            : variant === "outlined" && !disabled
            ? styles
            : variant === "contained" && !disabled
            ? styles1
            : disabled && variant === "contained"
            ? {
                "&:disabled": {
                  fontWeight: "700",
                  border: "none",
                  color: "#6A6A78 !important",
                  // backgroundColor: '#2F3344',
                },
              }
            : disabled
            ? disabledStyles
            : ""
        }
      >
        <p className="w-full font-outFitRegular text-xs md:text-sm ">{children}</p>
      </Button>
    </div>
  );
};

export default CustomButton;
