import React from "react";
import { Link } from "react-router-dom";

const CategoryCard = () => {
  let categoriesData = [
    {
      title: "BEDDING",
      url: "/bedding",
      id: 1,
      image: "/images/productsLarge/bedding.png",
    },
    {
      title: "BATH",
      url: "/bath",
      id: 2,
      image: "/images/productsLarge/bath.png",
    },
    {
      title: "WINDOW",
      url: "/window",
      id: 3,
      image: "/images/productsLarge/window.png",
    },
    {
      title: "DECOR",
      url: "/decor",
      id: 4,
      image: "/images/productsLarge/decor.png",
    },

    {
      title: "CLOTHING",
      url: "/clothing",
      id: 5,
      image: "/images/productsLarge/clothing.png",
    },
  ];
  return (
    <div className=" w-full">
      <p className="mb-6 font-outFitRegular text-3xl">Our Categories</p>
      <hr className="mb-8 w-full text-shadeGray" />

      <div className="flex flex-col gap-4">
        {categoriesData &&
          categoriesData.map((item) => (
            <div key={item?.id}>
              <div className="h-[50vh] w-full bg-shadeBlue">
                <img
                  src={item?.image}
                  className="h-full w-full object-cover"
                  alt="categories"
                />
              </div>
              <div className="mt-2 flex w-full justify-between">
                <p className="font-outFitSemiBold text-xl">{item?.title}</p>
                <Link to={item?.url}>
                  {" "}
                  <p className="font-outFitSemiBold text-xs underline">
                    EXPLORE
                  </p>
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CategoryCard;
