import React from "react";
import Backdrop from "@mui/material/Backdrop";
import { Box, CircularProgress, Modal } from "@mui/material";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fcfcfc",
  boxShadow: 24,
};

const CallBackModal = ({ open, handleClose }) => {

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="font-outFitRegular"
      >
        <Box
          sx={style}
          style={{
            textAlign: "",
            borderRadius: "8px",
            outline: "none",
          }}
        >
          <div className="flex flex-col gap-3 lg:px-[58px] lg:pt-[38px] lg:pb-[52px] p-7 lg:w-[496px] w-[320px]">
            <div
              onClick={handleClose}
              className="absolute lg:right-[58px] right-7 cursor-pointer"
            >
              {closeicon}
            </div>
            <div className="flex flex-col justify-center items-center">
              <img src="/images/shapes/successVector.svg" alt="success" className="w-[194px] h-[140px]" />
            </div>
            <p className="font-outfitMedium lg:text-[28px] text-2xl text-center">Thank You</p>
            <p className="font-outfitRegular lg:text-lg text-base text-center">Your submission has been received. We will be in touch & contact you soon!</p>
          </div>
        </Box>
      </Modal>

    </>
  );
};

export default CallBackModal;

const closeicon = (
  <svg
    width="32"
    height="31"
    viewBox="0 0 32 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7905 11.917L12.2031 19.3674"
      stroke="#222222"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.789 19.3722L12.1953 11.9141"
      stroke="#222222"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.8596 1.27539H9.13365C4.3504 1.27539 1.35156 4.60101 1.35156 9.30724V22.0065C1.35156 26.7127 4.33615 30.0383 9.13365 30.0383H22.858C27.6571 30.0383 30.6432 26.7127 30.6432 22.0065V9.30724C30.6432 4.60101 27.6571 1.27539 22.8596 1.27539Z"
      stroke="#222222"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
