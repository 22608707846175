import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../../helpers/axios";

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([{}, {}, {}, {}, {}]);


  const fetchTestimonials = () => {
    axiosInstance
      .get(`testimonials`)
      .then((res) => {
        setTestimonials(res?.data?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);
  return (
    <div className=" w-full">
      <p className="mb-6 font-outFitRegular text-3xl leading-relaxed">
        What Our Customers Are Saying
      </p>
      <hr className="mb-8 w-full text-shadeGray" />

      <div className="flex flex-col gap-10">
        {testimonials &&
          testimonials.map((item, index) => (
            <div key={index}>
              <div className="mb-6 flex justify-center gap-3">
                {Array.from({ length: item?.rating }, (star, starIndex) => (
                  <img key={starIndex} src={"/images/star.svg"} alt="star" className="w-6" />
                ))}
              </div>
              <div className="max-h-[30vh] w-full text-start font-outFitRegular text-lg">
                "{item?.content}"
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Testimonial;
