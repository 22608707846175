import Validator from "validatorjs";
import React, { useState } from "react";
import CustomButton from "./common/Button";
import axiosInstance from "../helpers/axios";
import { CircularProgress } from "@mui/material";
import TelephoneInput from "./common/TelephoneInput";
import { showToastMessage } from "./../helpers/toastmessage";

const GetaCall = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);

  const fields = {
    phone: "",
    lead_type: "CALLBACK",
  };
  const [params, setParams] = useState(fields);

  const [errors, setErrors] = useState(fields);

  const validate = (parameters, rule) => {
    const validator = new Validator(parameters, rule);
    if (validator.fails()) {
      const fieldErrors = {};

      for (const key in validator.errors.errors) {
        fieldErrors[key] = validator.errors.errors[key][0];
      }

      setErrors(fieldErrors);
      return false;
    }
    setErrors({});
    return true;
  };

 

  const handleSubmit = (e) => {
    let rules = {
      phone: "required",
    };
    if (!validate(params, rules)) {
      const err = Object.keys(errors);
      if (err?.length) {
        const input = document.querySelector(`input[name=${err[0]}]`);
      }
      setShowError(true);

      return;
    }

    if (isNotValid) {
      setErrors({ ...errors, phone: "Please Enter Valid Number" });
      setShowError(true);
      return;
    }

    //api call

    let postData = {
      // phone: `${params?.phone?.number?.dialCode}${params?.phone?.number?.number}`,
      phone: params.phone,
      lead_type: params?.lead_type,
    };

    setLoading(true);

    axiosInstance
      .post("/lead", postData)
      .then((res) => {
        setLoading(false);
        setParams(fields);
        if (res?.data?.data?.message) setSubmitted(true);
      })
      .catch((error) => {
        showToastMessage("Something Went Wrong !", "error");
      });
  };

  const onPhoneNumberChange = (isValid, value, selectedCountryData) => {
    // to remove white space characters
    setShowError(false);
    setIsNotValid(false);
    let temp = value.replace(/\s/g, "");

    setParams({ ...params, phone: temp });
    if (!isValid) {
      setIsNotValid(true);
      setErrors({ ...errors, phone: "Enter valid phone number" });
    } else {
      setErrors({ ...errors, phone: "" });
      setIsNotValid(false);
    }
  };

  return (
    <section className="px-4">
      <div className="m-0 flex justify-center sm:m-10">
        <div className="m-auto flex  w-full flex-col items-center  rounded-lg bg-darkGray px-4  py-6    text-center opacity-[0.8] sm:w-1/2 sm:px-20">
          <h4 className="text-white ">Request a call back</h4>
          <p className=" body4 text-white/50">
            Please enter your number & we’ll call you back!
          </p>
          <br />

          <div className="relative w-full">
            <TelephoneInput
              onPhoneNumberChange={onPhoneNumberChange}
              params={params}
              errors={errors}
            />

            <CustomButton
              onClick={handleSubmit}
              height="44px"
              width="absolute  top-1 m-auto w-fit sm:w-44 right-1"
              borderRadius="8px"
              variant="contained"
              size="large"
            >
              {loading ? (
                <div className="flex items-center justify-center font-outFitRegular text-sm text-white">
                  <CircularProgress
                    style={{ width: "20px", height: "20px", color: "#74BFB6" }}
                  />
                  <span className="">Loading...</span>
                </div>
              ) : (
                <p className="font-outFitRegular text-sm text-white">
                  Get a Call
                </p>
              )}
            </CustomButton>
          </div>

          {showError ? (
            <p className="body4 relative mt-2 text-center text-xs text-red">
              {errors?.phone}
            </p>
          ) : null}

          <br />
          <p className="body4 text-white">
            {submitted
              ? "Thank you, your response is submitted. We will get back to you soon ! "
              : ""}
          </p>
        </div>
      </div>
    </section>
  );
};

export default GetaCall;
