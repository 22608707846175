import React, { useMemo, useState } from "react";
import BreadCrumb from "../components/common/BreadCrumb";
import CustomButton from "../components/common/Button";
import CallBackModal from "../components/Modals/CallBackModal";
import EnquiryModal from "../components/Modals/EnquiryModal";
import ColorPicker from "../components/common/ColorPicker";

import PatternPicker from "../components/common/PatternPicker";
import SizePicker from "../components/common/SizePicker";
import axiosInstance from "../helpers/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import updateMeta from "../helpers/seo-meta";
import { useEffect } from "react";
import Loader from "../components/Loader";

const staticAdditionalInformation = [
  {
    title: "Style",
    content: "",
  },
  {
    title: "Color",
    content: "",
  },
  {
    title: "Size",
    content: "",
  },
];

const ProductDetails = () => {
  let { category, id, subcategory } = useParams();

  const path = [
    {
      path: "home",
      url: "/",
    },
    {
      path: category,
      url: `/${category}`,
    },
    {
      path: id,
      url: id,
    },
  ];

  let navigate = useNavigate();

  const [additionalInformation, setAdditionalInformation] = useState(
    staticAdditionalInformation
  );

  const [images, setImages] = useState([]);
  const [defaultImages, setDefaultImages] = useState([]);
  const [currentImg, setCurrentImg] = useState();
  const [size, setSize] = useState([]);
  const [colors, setColors] = useState([]);
  const [patterns, setPatterns] = useState([]);

  const [iscallBack, setCallBack] = useState(false);
  const [isEnquiry, setEnquiry] = useState(false);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);

  const handleCallBack = () => {
    setCallBack(!iscallBack);
  };
  const handleEnquiry = () => {
    setEnquiry(!isEnquiry);
  };

  const handleCurrentImage = (id, img_url) => {
    setCurrentImg(img_url);
    let list = [...defaultImages];

    const updatedList = list.map((item) => {
      return { ...item, is_active: id === item.id };
    });
    setDefaultImages(updatedList);
  };

  const fetchProduct = () => {
    setLoading(true);
    axiosInstance
      .get(`products/view/${id}`)
      .then((res) => {
        let response = res.data?.data;

        setProduct(response);

        //Handling default images
        if (response && response.product && response.product.default_images) {
          const addingIsActive = response.product.default_images.map(
            (item, index) => {
              return { ...item, is_active: index === 0 };
            }
          );
          setDefaultImages(addingIsActive);
        }

        //initial default Image
        setCurrentImg(response.product.default_images[0]?.img_url);

        //handling images for filteration
        if (response && response.product && response.images) {
          const addingIsActive = response.images.map((item, index) => {
            return { ...item, is_active: index === 0 };
          });
          setImages(addingIsActive);
        }

        //handling additional Information
        let information = [...additionalInformation];
        information[0].content = response?.product?.style;
        information[1].content = response?.product?.color;
        information[2].content = response?.product?.size;
        setAdditionalInformation(information);

        //handling Sizes

        let filterSizes = response?.filters?.find(
          (item) => item.filter_type === "size"
        );

        let sizeData = filterSizes?.child?.map((item, index) => {
          return { ...item, is_active: false };
        });

        setSize(sizeData);

        //handling Colors
        let filterColors = response?.filters?.find(
          (item) => item.filter_type === "color"
        );

        let colorsData = filterColors?.child?.map((item, index) => {
          return { ...item, is_active: false };
        });

        setColors(colorsData);

        //handling patterns
        let filterPatterns = response?.filters?.find(
          (item) => item.filter_type === "pattern"
        );
        let patternData = filterPatterns?.child?.map((item, index) => {
          return { ...item, is_active: false };
        });
        setPatterns(patternData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useMemo(() => {
    fetchProduct();
  }, [id]);

  const handlePatternsImages = (pattern) => {
    //pattern active
    let patternsList = [...patterns];
    patternsList = patternsList.map((item) => {
      return { ...item, is_active: pattern.id === item.id };
    });

    setPatterns(patternsList);

    //filterImage based on id

    let imagesList = images?.find((item) => item.unit === pattern.unit);
    let updatedList = [];

    if (imagesList && imagesList.images.length > 0) {
      updatedList = imagesList.images.map((item, index) => ({
        ...item,
        is_active: index === 0,
      }));
    } else {
      const defaultImages = product?.product?.default_images || [];
      updatedList = defaultImages.map((item, index) => ({
        ...item,
        is_active: index === 0,
      }));
    }

    setDefaultImages(updatedList);
    setCurrentImg(updatedList[0]?.img_url);
  };

  const handleColorPicker = (color, Colorindex) => {
    let colorList = [...colors];
    let updatedColorList = colorList.map((item, index) => {
      return {
        ...item,
        is_active: Colorindex === index,
      };
    });

    setColors(updatedColorList);

    //filterImage based on colorCode

    // console.log(color, "color");

    let imagesList = images?.find(
      (item) => item.color_code === color?.color_code
    );

    // console.log(imagesList, "imagesList");
    let updatedList = [];

    if (imagesList && imagesList.images.length > 0) {
      updatedList = imagesList.images.map((item, index) => ({
        ...item,
        is_active: index === 0,
      }));
    } else {
      const defaultImages = product?.product?.default_images || [];
      updatedList = defaultImages.map((item, index) => ({
        ...item,
        is_active: index === 0,
      }));
    }

    setDefaultImages(updatedList);
    setCurrentImg(updatedList[0]?.img_url);
  };

  const handleViewMore = () => {
    navigate(`/${category}/${subcategory}`);
  };

  useEffect(() => {
    document.title = "Products | DuvetHome ";
    updateMeta(
      "description",
      "Duvet Ltd offers premium bedding products for a comfortable and luxurious sleep experience. Shop now for high-quality duvets, pillows, and more."
    );



  }, []);

  const [activeIndex, setActivIndex] = useState(1)
  console.log(activeIndex, 'activeIndex')

  const incrementActiveIndex = () => {

    if (defaultImages.length < 4) {
      let element = document.querySelector(`.th-item-${defaultImages.length}`)
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      return;
    }

    else if (activeIndex === 1) {
      setActivIndex(activeIndex + 3)
      let element = document.querySelector(`.th-item-${activeIndex + 3}`)
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    else if (activeIndex === defaultImages.length) {
      setActivIndex(activeIndex)
      let element = document.querySelector(`.th-item-${activeIndex}`)
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    else {
      setActivIndex((prevActiveIndex) => prevActiveIndex + 1)
      let element = document.querySelector(`.th-item-${activeIndex + 1}`)
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

  }

  const decrementActiveIndex = () => {

    if (activeIndex === 1) {
      setActivIndex(1)
      let element = document.querySelector(`.th-item-${1}`)
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    else if (activeIndex === defaultImages.length) {
      setActivIndex(activeIndex - 3)
      let element = document.querySelector(`.th-item-${activeIndex - 3}`)
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    else {
      setActivIndex((prevActiveIndex) => prevActiveIndex - 1)
      let element = document.querySelector(`.th-item-${activeIndex - 1}`)
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }

  // image hover zoom
  useEffect(() => {

    if (!loading && window.innerWidth > 768) {
      const container = document.getElementById("thisContainer");
      const img = document.querySelector(".hover-img");

      container.addEventListener("mousemove", onZoom);
      container.addEventListener("mouseover", onZoom);
      container.addEventListener("mouseleave", offZoom);

      function onZoom(e) {
        const x = e.clientX - e.target.offsetLeft;
        const y = e.clientY - e.target.offsetTop;
        img.style.transformOrigin = `${x}px ${y}px`;
        img.style.transform = "scale(2)";
      }
      function offZoom(e) {
        img.style.transformOrigin = `center center`;
        img.style.transform = "scale(1)";
      }
    }
  }, [loading])


  return (
    <div className="mt-1 w-full  px-1 pb-2 md:mt-[12vh] md:px-4 md:pb-0">
      {/* Breadcrumb section */}
      <section>
        <div className="py-0 md:py-3 lg:px-10">
          <BreadCrumb links={path}></BreadCrumb>
        </div>
      </section>

      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>

          <div className="flex lg:flex-row flex-col w-full h-full items-start lg:gap-7 gap-5 lg:px-10">


            <div className="flex lg:w-[35%] w-full h-full flex-col gap-2 lg:flex-shrink-0">

              <div id="thisContainer" className="h-[60vh] w-full relative  overflow-hidden cursor-zoom-in">
                <img
                  style={{ width: '100%', height: '100%', objectFit: 'cover', transformOrigin: 'center' }}
                  className="h-full w-full object-fit md:h-full hover-img pointer-events-none"
                  src={currentImg}
                  alt="active"
                />
              </div>

              <div className="w-full h-full relative">


                {defaultImages.length > 3 ? <>
                  <div onClick={decrementActiveIndex}
                    className={`absolute left-2 h-full lg:flex hidden flex-col items-center justify-center cursor-pointer `}>
                    <img src="/images/icons/circleArrow.svg" alt="circleArrow" className="w-10 h-10 bg-gray/80 rounded-full -rotate-180" />
                  </div>

                  <div onClick={incrementActiveIndex}
                    className={`absolute right-2 h-full lg:flex hidden flex-col items-center justify-center cursor-pointer`}>
                    <img src="/images/icons/circleArrow.svg" alt="circleArrow" className="w-10 h-10 bg-gray/80 rounded-full" />
                  </div>
                </> : <></>}


                <div className="flex h-full w-full lg:gap-0 gap-3 lg:overflow-x-hidden overflow-x-auto">

                  {defaultImages &&
                    React.Children.toArray(defaultImages.map((item, index) => (
                      <div
                        className={`${item?.is_active ? "rounded-md  border-2 border-oliveGreen" : ""
                          } h-[18vh] flex-shrink-0 w-[18vh] flex-none md:w-1/3 p-1 ${defaultImages.length === index + 1 ? 'last-thumbnail' : index === 0 ? 'first-thumbnail' : ''} th-item-${index + 1}`}
                      >
                        <img
                          className="h-full w-full rounded-md object-fit transition-all group-hover:scale-[1.1"
                          src={item?.img_url}
                          onClick={() =>
                            handleCurrentImage(item?.id, item?.img_url)
                          }
                          alt="default"
                        ></img>
                      </div>
                    )))}
                </div>
              </div>

            </div>

            <div className="flex  h-full w-full flex-col overflow-auto px-2 md:px-0 md:pl-2">
              <div className="w-full">
                <h1 className="mt-2 font-outFitRegular text-[1.4rem] font-[500] text-oliveGreen  md:mt-0 md:text-[2.2rem]">
                  {product?.product?.name}
                </h1>
                <p className="mt-2 pr-10 text-justify font-outFitRegular text-[14px] font-[400]  leading-[20px] text-darkGray md:text-[1.2rem] md:leading-[26px]">
                  {product?.product?.short_description}
                </p>

                {colors && colors.length > 0 ? (
                  <div className="mt-4 flex h-12 items-center gap-4">
                    <p className="font-outFitRegular text-[1rem] font-[500]  text-leastGray md:text-[1.1rem]">
                      Color:
                    </p>
                    {colors &&
                      React.Children.toArray(
                        colors.map((item, index) => (
                          <ColorPicker
                            clr={item?.unit}
                            is_active={item?.is_active}
                            handleClick={() => {
                              handleColorPicker(item, index);
                            }}
                          />
                        ))
                      )}
                  </div>
                ) : (
                  ""
                )}

                {patterns && patterns.length > 0 ? (
                  <div className="mt-4 items-center gap-4">
                    <p className="mb-2 font-outFitRegular text-[1rem] font-[500]  text-darkGray md:text-[1.1rem]">
                      Pattern:
                    </p>
                    <div className="flex flex-wrap gap-4">
                      {patterns &&
                        React.Children.toArray(patterns.map((item, index) => (
                          <div
                            onClick={() => {
                              handlePatternsImages(item);
                            }}
                          >
                            <PatternPicker
                              img={item?.img_url}
                              is_active={item?.is_active}
                            />
                          </div>
                        )))}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {size && size.length > 0 ? (
                  <div className="mb-4 mt-4 items-center gap-4">
                    <p className="mb-2 font-outFitRegular text-[1rem] font-[500]  text-leastGray md:text-[1.1rem]">
                      Size:
                    </p>
                    <div className="flex h-auto w-full flex-wrap gap-4">
                      {size &&
                        React.Children.toArray(size.map((item) => (
                          <SizePicker
                            size={item?.unit}
                            is_active={item?.is_active}
                          />
                        )))}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {product?.product?.selling_price ? (
                  <div className="mt-3 flex items-center gap-4">
                    <p className="font-outFitRegular text-[1rem] font-[500]  text-leastGray md:text-[1.1rem]">
                      Starts From:
                    </p>
                    <p className="body2 text-oliveGreen">
                      ${product?.product?.selling_price}
                    </p>
                  </div>
                ) : (
                  ""
                )}

                <div className="mt-4 flex w-full gap-4 md:gap-8">
                  <CustomButton
                    onClick={handleCallBack}
                    variant="outlined"
                    size="large"
                    height="56px"
                    width={"md:w-[220px] w-1/2"}
                    borderRadius={"8px"}
                  // disabled={loading}
                  >
                    Request a Call Back
                  </CustomButton>

                  <CustomButton
                    onClick={handleEnquiry}
                    variant="contained"
                    size="large"
                    height="56px"
                    width={"md:w-[220px] w-1/2"}
                    // disabled={loading}
                    borderRadius={"8px"}
                  >
                    Enquire Now
                  </CustomButton>

                  <CallBackModal
                    open={iscallBack}
                    handleClose={handleCallBack}
                  />
                  <EnquiryModal open={isEnquiry} handleClose={handleEnquiry} />
                </div>

                <div className="mt-4 w-full">
                  <div className="w-full">
                    <span>
                      <h4 className=" text-oliveGreen">Product Description</h4>
                      <hr className="w-10 text-lightBlue" />
                    </span>

                    <div className="w-full md:px-0 md:pr-10">
                      <p className="mt-2 text-justify font-outFitRegular text-base leading-[1.6rem] md:text-[1.2rem]">
                        {product?.product?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/* You may also like */}

          <section className="mt-2">
            <h3 className="my-4 text-center md:my-8">You may also like</h3>
            <div className="m-auto grid w-full grid-cols-1 gap-6 md:grid-cols-3 md:gap-16 lg:px-10 px-2">
              {product &&
                product?.recommended?.map((item) => {
                  return (
                    <Link
                      to={`/${item?.category_slug}/${subcategory}/${item?.slug}`}
                      className="flex cursor-pointer flex-col items-center justify-center gap-4 "
                    >
                      <img
                        className="lg:h-[50vh] h-full object-contain w-full rounded-md flex-shrink-0"
                        src={item?.images[0]?.img_url}
                        alt="product_images"
                      />
                      <p className="title2 lg:pb-0 pb-5">{item?.name || "--"}</p>
                    </Link>
                  );
                })}
            </div>

            <p
              onClick={handleViewMore}
              className="title2 mx-auto my-8 w-fit cursor-pointer border-b text-center text-lightBlue"
            >
              View More
            </p>
          </section>
        </>
      )}
    </div>
  );
};

export default ProductDetails;
