import React from "react";

const TextArea = ({
  placeholder,
  id,
  name,
  rows,
  className,
  value,
  error,
  handleChange,
  helperText,
  readOnly,
}) => (
  <div className="relative">
    <textarea
      readOnly={readOnly}
      className={`font-outfitRegular border-border w-full border  ${className ? className : "bg-transparent"
        } text-SpaceCadet  placeholder-placeholder hover:border-SpaceCadet rounded-lg p-4 outline-none placeholder:text-CoolGrey placeholder:font-outFitRegular focus:border-[2px] focus:border-[#74BFB6] ${className}  ${error && "border-GlowingBrakeDisc placeholder:text-GlowingBrakeDisc"
        }`}
      name={name}
      id={id}
      rows={rows}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
    />
    {helperText && (
      <p className="text-red absolute -bottom-3 ml-4 text-xs">
        {helperText}
      </p>
    )}
  </div>
);

export default TextArea;
