import React, { useEffect, useState } from "react";

const DragOfTranslator = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({
    x: 300,
    y: 1200,
  });
  const [offset, setOffset] = useState({ x: 300, y: 1200 });
  const [open, setOpen] = useState(false);

  const elementStyle = {
    position: "fixed",
    left: `${position.x}px`,
    top: `${position.y}px`,
    cursor: isDragging ? "grabbing" : "grab",
  };

  useEffect(() => {
    const initialX = window.innerWidth - 120;
    const initialY = window.innerHeight - 180;
    setPosition({ x: initialX, y: initialY });
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        // pageLanguage: "en",
        includedLanguages: "en,ar,nl,fr,de,it,pt,es,uk,zh-CN,ru,hi",
        defaultLanguage: "en",

        multilanguagePage: false,
        gaTrack: true,

        autoDisplay: false,
      },
      "google_translate_element"
    );

    const select = document.querySelector(".goog-te-combo");

    if (select) {
      const options = select.querySelectorAll("option");
      options.forEach((option) => {
        option.textContent = option.textContent.toUpperCase();
      });
      select.value = "en";
      select.style.width = "38px";
      select.style.fontFamily = "OutFit Medium, sans-serif";
      select.dispatchEvent(new Event("change"));
    }
  };

  useEffect(() => {
    if (typeof window && window.innerWidth < 769) {
      if (!(typeof window).google || !(typeof window).google.translate) {
        const addScript = document.createElement("script");
        addScript.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
      } else {
        googleTranslateElementInit();
      }
    }
  }, []);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    // e.preventDefault();
    document.body.style.overflow = "hidden";
    const touch = e.touches[0];
    setOffset({
      x: touch.clientX - position.x,
      y: touch.clientY - position.y,
    });
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      // e.preventDefault();
      const touch = e.touches[0];
      setPosition({
        x: touch.clientX - offset.x,
        y: touch.clientY - offset.y,
      });
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div
      className="draggable z-[99999] h-[50px] w-[90px]"
      style={elementStyle}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="  w-[90px] items-center md:hidden">
        <div className="flex w-full flex-row-reverse items-center justify-center">
          <img
            src="/images/newGlobe.svg"
            alt=""
            className="mt-2"
            onClick={handleOpen}
          />

          <div className="w-[60px]">
            <div
              className={`${
                open ? "block" : "hidden"
              } custom-translate-container`}
            >

             
              <div id="google_translate_element" className="w-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DragOfTranslator;
