import React from "react";


const SmallScreenAboutUs = () => {
  return (
    <div className=" w-full">
      <p className="mb-6 font-outFitRegular text-3xl">About Us</p>
      <hr className="mb-6 w-full text-shadeGray" />

      <div className="mx-auto  w-full text-justify">
        <p className="font-outFitRegular text-[16px] font-[400] leading-[24px] text-darkGray">
          DUVET HOME - Designs, Manufactures & Exports stylish trendy & modern
          Home Textile & Garment products to buyers across the World. We are a
          highly experienced sourcing and surplus chain management organization
          that certifies to deliver and serve high quality products.
        </p>
      </div>

      <div className="mt-6 flex h-[50%] w-full items-center justify-center rounded-[48px]">
        <img
          src={"/images/about_us_jpeg.jpg"}
          alt="about"
          className="h-full w-full  object-cover"
        />
      </div>
    </div>
  );
};

export default SmallScreenAboutUs;
