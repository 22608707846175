import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { headers } from "./helpers";

import axiosInstance from "../../helpers/axios";

const Header = () => {
  const [menus, setMenus] = useState(headers);
  const [scrollDirection, setScrollDirection] = useState("up");
  const [show, setShow] = useState(false);
  let { pathname } = useLocation();
  let numberOfSlashes = (pathname.match(/\//g) || []).length;

  const handleMenusOnHover = (activeIndex) => {
    const updatedMenus = menus?.map((item, index) => ({
      ...item,
      active: index === activeIndex,
      dropdown: index === activeIndex,
      subList: item.subList.map((subItem, innerIndex) => ({
        ...subItem,
        Isactive: false,
      })),
    }));
    setMenus(updatedMenus);
  };

  const handleMainCategorySelect = (activeIndex) => {
    const updatedMenus = menus?.map((item, index) => ({
      ...item,
      active: index === activeIndex,
      dropdown: false,
    }));
    setMenus(updatedMenus);
  };

  const handleSubLinkOnOver = (index, innerIndex) => {
    let list = [...menus];
    list[index]?.subList?.map((item, indexx) =>
      indexx === innerIndex ? (item.Isactive = true) : (item.Isactive = false)
    );

    list[index]["dropdown"] = true;
    setMenus(list);
  };
  const handleSubLinkOnClick = (parentIndex) => {
    const updatedMenus = menus?.map((item, index) => ({
      ...item,
      active: index === parentIndex,
      dropdown: false,
    }));
    setMenus(updatedMenus);
  };

  const handleMenusOnLeave = () => {
    const updatedMenus = menus?.map((item, index) => ({
      ...item,
      active: false,
      dropdown: false,
    }));
    setMenus(updatedMenus);
  };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        // pageLanguage: "en",
        includedLanguages: "en,ar,nl,fr,de,it,pt,es,uk,zh-CN,zh,ru,hi",
        defaultLanguage: "en",

        multilanguagePage: false,
        gaTrack: true,

        autoDisplay: false,
      },
      "google_translate_element"
    );

    const select = document.querySelector(".goog-te-combo");

    if (select) {
      select.value = "en";
      select.style.width = "70px";
      select.style.fontFamily = "OutFit Medium, sans-serif";

      select.dispatchEvent(new Event("change"));
    }
  };

  useEffect(() => {
    if (typeof window && window.innerWidth > 769) {
      setShow(true);
      if (!(typeof window).google || !(typeof window).google.translate) {
        const addScript = document.createElement("script");
        addScript.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
      } else {
        googleTranslateElementInit();
      }
    }
  }, []);

  const fetchSubCategories = () => {
    axiosInstance
      .get(`/all-categories`)
      .then((res) => {
        let response = res?.data?.category_data;
        let menulist = [...menus];

        response.map(
          (item, index) => (menulist[index + 1]["subList"] = item.child)
        );
        setMenus(menulist);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchSubCategories();
  }, []);

  const handleScroll = () => {
    const currentScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollTop >= 600) {
      setScrollDirection("down");
    } else {
      setScrollDirection("up");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="fixed right-4 top-[1%] z-[9999] ">
        <div className="flex h-full items-center">
          <img
            src={
              scrollDirection === "up"
                ? "/images/whiteGlobe.svg"
                : "/images/globeIcon.png"
            }
            alt="globe"
            className=""
          />
          {show ? <div id="google_translate_element"></div> : null}
        </div>
      </div>

      <div
        className={`${
          scrollDirection === "down" || numberOfSlashes >= 3
            ? "bg-white shadow-2xl transition-all duration-500"
            : "top-[1%] bg-transparent transition-all duration-500"
        } fixed top-0 z-[999]  flex h-[12vh] w-full  justify-between px-4`}
      >
        <Link to="/">
          {" "}
          <div className="">
            <img
              src={
                scrollDirection === "down" || numberOfSlashes >= 3
                  ? "/images/duvetNewLogo.svg"
                  : "/images/logoNew.svg"
              }
              alt=""
              className="absolute top-[20%]  w-[10%]"
            />
          </div>
        </Link>

        <div className="flex h-full flex-col gap-2 ">
          <div className="flex w-full justify-end">
            <div className="flex  h-[20px]  w-[95px]">
              <div className="flex h-full items-center"></div>
            </div>
          </div>
          <div className="h-full" onMouseLeave={handleMenusOnLeave}>
            <div
              className={`flex  gap-3  ${
                scrollDirection === "down" || numberOfSlashes >= 3
                  ? "h-[65%] transition-all duration-300 "
                  : "h-[50%] bg-white transition-all duration-300"
              }`}
            >
              {menus?.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    index === 6 ? "" : "border-r border-gray "
                  } flex items-center pr-3 font-outFitBold  ${
                    index === 0 ? "pl-2" : ""
                  }`}
                >
                  <div>
                    <Link
                      to={`${
                        [0, 6, 7].includes(index)
                          ? item?.url
                          : `/${item?.name.toLowerCase()}`
                      }`}
                    >
                      <p
                        className={`${
                          (pathname.includes("/")
                            ? pathname.split("/")[1]
                            : pathname) === item?.name.toLowerCase() ||
                          item?.active
                            ? "animate-border-left font-outFitBold text-oliveGreen"
                            : ""
                        } cursor-pointer font-[500]`}
                        onMouseEnter={() => handleMenusOnHover(index)}
                        onClick={() => {
                          handleMainCategorySelect(index);
                        }}
                      >
                        {[6, 7].includes(index) ? (
                          <>{item?.name?.replace("-", " ")}</>
                        ) : (
                          <>{item?.name}</>
                        )}
                      </p>
                    </Link>
                  </div>

                  {[0, 6, 7].includes(index) ? (
                    ""
                  ) : (
                    <>
                      {item?.active && item?.dropdown ? (
                        <div className="shadow-all modal absolute top-[80%] z-50 h-auto w-auto rounded-[18px] bg-white px-6 py-4">
                          {React.Children.toArray(
                            item?.subList?.map((Inneritem, innerIndex) => (
                              <div
                                className="flex flex-col gap-2"
                                key={innerIndex}
                              >
                                <Link
                                  to={`/${item?.name.toLowerCase()}/${
                                    Inneritem?.slug
                                  }`}
                                >
                                  <p
                                    onClick={() => {
                                      handleSubLinkOnClick(
                                        index,
                                        innerIndex,
                                        item.name,
                                        Inneritem.name
                                      );
                                    }}
                                    onMouseEnter={() =>
                                      handleSubLinkOnOver(index, innerIndex)
                                    }
                                    className={
                                      (pathname.includes("/")
                                        ? pathname.split("/")[2]
                                        : pathname) ===
                                        Inneritem?.slug?.toLowerCase() ||
                                      Inneritem?.Isactive
                                        ? "mb-3 translate-x-4 transform cursor-pointer font-outFitRegular text-sm uppercase text-oliveGreen duration-1000"
                                        : "darkGray mb-3 cursor-pointer font-outFitRegular text-sm uppercase"
                                    }
                                  >
                                    {Inneritem?.name}
                                  </p>
                                </Link>
                              </div>
                            ))
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
