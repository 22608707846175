import React from "react";

const SubHeading = ({ text, styles }) => {
  return (
    <p className={`title2 relative  w-fit text-oliveGreen ${styles}`}>
      {text}
      <svg
        className="absolute bottom-0 right-0 w-1/2"
        height="1"
        viewBox="0 0 60 1"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="-4.37114e-08"
          y1="0.5"
          x2="60"
          y2="0.499995"
          stroke="#3A5A40"
        />
      </svg>
    </p>
  );
};

export default SubHeading;
