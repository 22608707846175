import React, { useRef, useState, useEffect, useMemo } from "react";
/** assets */
import rightArr from "../../public/images/right-arr.svg";
import filterIcon from "../../public/images/filter-square.svg";

/** package */
import { useMediaQuery, useScroll } from "@react-hooks-library/core";
import ColorPicker from "./../components/common/ColorPicker";
import CategoryPicker from "./../components/common/CategoryPicker";
import Phone from "../components/common/Phone";
import CustomButton from "../components/common/Button";
import gsap from "gsap/dist/gsap";
import { Link, useLocation } from "react-router-dom";
import GetaCall from "../components/GetaCall";
import FilterButtons from "../components/common/FilterButtons";
import { toTitleCase } from "./../helpers/toTitleCase";
import Loader from "../components/Loader";
/** components */

const MainListing = ({
  ApplyFilter,
  ResetFilter,
  filterIds,
  sub_categories,
  filterslist,
  handleFilter,
  prodList,
  setActiveCat,
  activeCat,
  listloading,
  getbyIdHeroData,
  catloading,
  subcategory,
  category,
}) => {
  let { pathname } = useLocation();

  // const sub_categories_list = [
  //   ...sub_categories,
  //   { id: 1, name: "All Products", image: "/images/all_products.svg" },
  // ].reverse();

  const [sub_categories_list, set_sub_categories_list] =
    useState(sub_categories);

  const [isNext, setNextDisabled] = useState(false);
  const [isPrev, setPrevDisabled] = useState(true);

  const box = useRef(null);
  const [scroll, setScroll] = useState({ x: 0, y: 0 });
  const [isfilter, setIsFilter] = useState(true);

  const ControlFilter = () => {
    setIsFilter(!isfilter);
  };

  useEffect(() => {
    let data = [
      { id: 1, name: "All Products", image: "/images/all_products.svg" },
      ...sub_categories,
    ];
    const pathnameSegments = pathname.split("/");
    if (pathnameSegments.length > 2) {
      const index = data.findIndex(
        (item) => item?.slug === pathnameSegments[2]
      );
      let removedData = data.splice(index, 1);
      data.splice(1, 0, removedData[0]);
      set_sub_categories_list(data);
    }
    else {
      set_sub_categories_list(data);
    }
  }, [sub_categories, pathname]);

  useEffect(() => {
    if (isfilter) {
      gsap.to(".filter_container", {
        height: 0,
        duration: 0.5,
        ease: "power2.out",
      });
    } else {
      gsap.to(".filter_container", {
        height: "auto",
        ease: "power2.out",
        duration: 0.5,
      });
    }
  }, [isfilter]);

  const prevClick = () => {
    setNextDisabled(false);
    setPrevDisabled(true);

    const container = box.current;
    if (container) {
      const slideWidth = container.offsetWidth / 10;
      gsap.to(container, { scrollLeft: "-=" + slideWidth * 10, duration: 1 });
    }
  };
  const nextClick = () => {
    setNextDisabled(true);
    setPrevDisabled(false);

    const container = box.current;
    if (container) {
      const slideWidth = container.offsetWidth / 10;
      gsap.to(container, { scrollLeft: "+=" + slideWidth * 10, duration: 1 });
    }
  };

  useScroll(box, ({ scrollX, scrollY }) =>
    setScroll({ x: scrollX, y: scrollY })
  );

  useEffect(() => {
    if (scroll.x >= 0.96) {
      setNextDisabled(true);
      setPrevDisabled(false);
    }
    if (scroll.x === 0) {
      setNextDisabled(false);
      setPrevDisabled(true);
    }
  }, [scroll.x]);

  useEffect(() => {
    if (subcategory) {
      const element = document?.getElementById("prod_section");
      element?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [subcategory]);

  return (
    <div className="flex flex-col gap-10">
      {/* Hero section */}

      <section className="relative  h-[50vh]  w-full    px-1 md:h-screen ">
        <div className="absolute left-0 right-0 top-[40%] m-auto ">
          <h1 className="text-center text-white">{getbyIdHeroData?.title}</h1>
          <p className="body3 mx-auto w-[60%] text-center text-white md:w-full">
            {getbyIdHeroData?.description}
          </p>
        </div>
        <video
          className="h-[50vh] w-full object-fill  md:h-screen"
          src={getbyIdHeroData?.banner}
          autoPlay={true}
          loop
          muted
          controls={false}
          poster={getbyIdHeroData?.poster}
        >
          <source src={getbyIdHeroData?.banner} type="video/mp4" />
        </video>
      </section>

      <section className="w-full" id="prod_section">
        <div className="m-auto grid grid-cols-1 justify-between sm:grid-cols-3 ">
          <div></div>
          <p className="body2 text-center">Shop by Categories</p>

          {sub_categories_list?.length > 6 ? (
            <div className="hidden w-full items-center justify-end gap-4 px-2 sm:flex md:px-4 ">
              <img
                src={rightArr}
                className={`rotate-180 cursor-pointer ${isPrev ? "opacity-20" : ""
                  }`}
                onClick={prevClick}
                alt="<-"
              />
              <img
                className={`z-[100] cursor-pointer ${isNext ? "opacity-20" : ""
                  }`}
                src={rightArr}
                onClick={nextClick}
                alt="->"
              />
            </div>
          ) : (
            ""
          )}
        </div>

        {catloading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            {sub_categories_list?.length > 0 ? (
              <div className="w-full px-4">
                <div
                  ref={box}
                  // className="m-auto mx-4 grid w-full grid-cols-3 gap-2 gap-x-5 py-4 sm:flex  sm:overflow-auto md:py-10"

                  className="grid w-full grid-cols-3 gap-2 gap-x-5 px-2 py-4 md:flex md:overflow-auto md:px-4"
                >
                  {sub_categories_list?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        id={`sub_cat${item?.slug}`}
                        onClick={() => setActiveCat(item)}
                        className={`${activeCat?.slug === item?.slug
                          ? "bg-oliveGreen text-white"
                          : "text-black"
                          }  w-full cursor-pointer  rounded-lg hover:shadow-3xl sm:w-fit `}
                      >
                        <div
                          id={item?.id}
                          className="group w-full p-2  text-center sm:w-[141px] sm:p-4 lg:text-start"
                        >
                          <img
                            className="m-auto h-[73px] w-[66px] rounded-lg transition-all group-hover:scale-[1.1] sm:h-[103px] sm:w-[98px]"
                            src={
                              item?.image
                                ? item?.image
                                : "/images/defualt_image.svg"
                            }
                            alt="sub_category"
                          />
                          <p className="font- mt-2 truncate text-center font-outFitMedium text-sm sm:mt-4">
                            {item?.name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="flex h-[20vh] items-center justify-center">
                <p className="body1"> Products Not Found !</p>
              </div>
            )}
          </>
        )}
      </section>

      {/* Grid list */}
      <section className="mx-4">
        <div className="flex w-full flex-col rounded-bl-3xl rounded-br-2xl ">
          <div className="flex justify-between rounded-bl-3xl rounded-br-2xl py-2 sm:py-0">
            <h5 className=" text-[#2E3031]">
              {activeCat?.name ?? "All Products"}
            </h5>

            <div
              onClick={ControlFilter}
              className="flex cursor-pointer items-center"
            >
              <img src={filterIcon} className="mr-2" alt="filter" />
              <h6 className=""> {!isfilter ? "Hide Filter" : "Filter"}</h6>
            </div>
          </div>

          <div
            className={`filter_container mt-0 h-auto  overflow-hidden rounded-bl-2xl rounded-br-2xl border-t border-Gainsboro shadow-filter_section  sm:mt-4`}
          >
            {filterslist?.length > 0 ? (
              <div
                className={`grid grid-cols-1  gap-x-4 bg-white sm:grid-cols-4`}
              >
                {filterslist?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`${filterslist.length === 1
                        ? "flex items-center  justify-evenly sm:gap-4"
                        : ""
                        } p-4`}
                    >
                      {" "}
                      <div className="flex justify-between ">
                        <p className="title2 ml-2">
                          {toTitleCase(item?.filter_type)}
                        </p>
                      </div>
                      <br />
                      {item?.filter_type !== "color" ? (
                        <div className="grid grid-cols-2 gap-4 w-fit">
                          {[
                            item?.child?.map((child, index) => {
                              return (
                                <CategoryPicker
                                  index={index}
                                  key={index}
                                  handleClick={() =>
                                    handleFilter(child?.filter_id)
                                  }
                                  is_active={child?.is_active}
                                  content={child?.unit}
                                />
                              );
                            }),
                          ]}
                        </div>
                      ) : (
                        <div className="grid grid-cols-4 lg:gap-x-7 lg:gap-y-5 gap-4 lg:w-fit">
                          {item?.child?.map((item, index) => {
                            return (
                              <ColorPicker
                                handleClick={() =>
                                  handleFilter(item?.filter_id)
                                }
                                is_active={item?.is_active}
                                clr={item?.color_code}
                                key={index}
                              />
                            );
                          })}
                        </div>
                      )}
                      <br />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex h-[20vh] items-center justify-center">
                <p className="body1"> Oops, No Filters Found !</p>
              </div>
            )}

            <FilterButtons
              ApplyFilter={ApplyFilter}
              ResetFilter={ResetFilter}
              data={filterIds}
            />
          </div>
        </div>

        {listloading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div>
            {prodList?.length > 0 ? (
              <div className="mt-12 w-full h-full grid grid-cols-2 gap-8 sm:grid-cols-4 ">
                {prodList.map((el, index) => {
                  const sub_category = subcategory
                    ? subcategory
                    : el.categories[0]
                      ? el.categories[0].sub_category_slug
                      : "default";
                  return (
                    <Link
                      key={index}
                      to={`/${category}/${sub_category}${"/" + el?.slug}`}
                      className="flex  flex-col items-center gap-2 pl-0 sm:gap-4"
                    >
                      <img
                        className="h-[165px] w-full object-fit shadow-2xl lg:w-full lg:h-full"
                        src={
                          el?.images[0]?.img_url
                            ? el?.images[0]?.img_url
                            : "/images/defualt_image.svg"
                        }
                        alt="picsum image"
                      />
                      <p className=" body3 w-full p-3 text-center  text-[#222222]">
                        {el?.name}
                      </p>
                    </Link>
                  );
                })}
              </div>
            ) : (
              <div className="flex h-[20vh] items-center justify-center">
                <p className="body1"> Products Not Found !</p>
              </div>
            )}
          </div>
        )}
      </section>
      {/* <div id="prod_section"></div> */}
      {/* Request callback */}
      <GetaCall />
    </div>
  );
};

export default MainListing;
