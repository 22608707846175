import React from "react";

const PatternPicker = ({ img, is_active, handleClick, id }) => {
  return (
    <div
      className={`flex h-20 w-20 cursor-pointer  items-center justify-center rounded-[8px] ${
        is_active ? "border-2 border-oliveGreen shadow-3xl" : ""
      }`}
    >
      <img src={img} alt="pattern" className="h-[80%] w-[80%] rounded-[8px]" />
    </div>
  );
};

export default PatternPicker;
