import React, { useState, useEffect, useRef } from "react";
import CustomButton from "../components/common/Button";
import TextArea from "../components/common/Textarea";
import Phone from "../components/common/Phone";
import { InputBox } from "../components/common/TextField";

import address from "../assets/icons/address.svg";
import call from "../assets/icons/call.svg";
import fax from "../assets/icons/fax.svg";
import whatsapp from "../assets/icons/whatsapp.svg";
import email from "../assets/icons/email.svg";
import global from "../assets/icons/global.svg";

import Validator from "validatorjs";
import axiosInstance from "../helpers/axios";
import { CircularProgress } from "@mui/material";
import TelephoneInput from "../components/common/TelephoneInput";
import updateMeta from "../helpers/seo-meta";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const fields = {
    name: "",
    // phone: { number: "", valid: false, dialCode: "" },
    phone: "",
    email: "",
    message: "",
    lead_type: "CONTACTUS",
  };
  const [params, setParams] = useState(fields);
  const [errors, setErrors] = useState(fields);

  const [loading, setLoading] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  // const onPhoneNumberChange = (isValid, value, selectedCountryData) => {
  //   // setParams({ ...params, phone: value, nationality: selectedCountryData.name })
  //   setParams({ ...params, phone: value });

  //   if (!isValid) {
  //     setErrors({ ...errors, phone: "Enter valid phone number" });
  //   } else {
  //     setErrors({ ...errors, phone: "" });
  //   }
  // };

  const onPhoneNumberChange = (isValid, value, selectedCountryData) => {
    // to remove white space characters

    let temp = value.replace(/\s/g, "");

    setParams({ ...params, phone: temp });
    if (!isValid) {
      setErrors({ ...errors, phone: "Enter valid phone number" });
    } else {
      setErrors({ ...errors, phone: "" });
    }
  };

  const handleChange = (e) => {
    setErrors("");
    const { name, value } = e.target;
    setParams({ ...params, [name]: value });
  };

  const validate = (parameters, rule) => {
    const validator = new Validator(parameters, rule);
    if (validator.fails()) {
      const fieldErrors = {};
      /* eslint-disable */
      for (const key in validator.errors.errors) {
        fieldErrors[key] = validator.errors.errors[key][0];
      }
      /* eslint-enable */
      setErrors(fieldErrors);
      return false;
    }
    setErrors({});
    return true;
  };

  const handleSubmit = (e) => {
    let rules = {
      name: "required|max:20",
      email: "required|email",
      phone: "required",
      message: "max:200",
      // "phone.number": "required",
    };
    if (!validate(params, rules)) {
      const err = Object.keys(errors);
      if (err?.length) {
        const input = document.querySelector(`input[name=${err[0]}]`);
      }

      return;
    }

    //api call

    let postData = {
      name: params?.name,
      email: params?.email,
      message: params?.message,
      phone: params.phone,
      lead_type: params?.lead_type,
    };

    setLoading(true);

    axiosInstance
      .post("/lead", postData)
      .then((res) => {
        setLoading(false);
        setParams(fields);
        setSubmitted(true);
        setParams({ ...params, phone: "", email: "", message: "", name: "" });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = "Contact Us | DuvetHome ";
    updateMeta(
      "description",
      "Duvet Ltd offers premium bedding products for a comfortable and luxurious sleep experience. Shop now for high-quality duvets, pillows, and more."
    );
  }, []);

  console.log(params, "params");

  return (
    <div className="px-1">
      <section className="m-auto    flex h-[90vh]  w-full  flex-col items-center  justify-center gap-y-0  bg-contact_mble_banner bg-cover bg-center bg-no-repeat  p-8 sm:gap-y-6 sm:bg-contact_banner  md:h-[100vh]">
        <div className="relative h-full w-full">
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <h3 className="text-center text-xl text-white md:text-4xl  md:text-oliveGreen">
              Let’s Connect With Our <br /> Work Team
            </h3>
            <p className="mb-20 mt-2 text-center font-outFitRegular text-base text-white sm:mb-0 md:mt-2 md:text-base md:text-oliveGreen">
              Create a harmonious tapestry of style, comfort, and elegance. Your
              dream home awaits!
            </p>
          </div>
        </div>
      </section>

      <section className="m-auto my-4 px-2 md:my-20 ">
        <div className="m-auto grid w-full grid-cols-1 gap-10 sm:w-[88%] sm:grid-cols-2">
          <div className="w-full ">
            <div>
              <h4 className="my-8 text-lg text-oliveGreen lg:text-[32px] lg:leading-tight">
                Keep Connected & Lets get in touch <br /> With our team
              </h4>
            </div>
            <div className="flex justify-between gap-8 ">
              <p className="title2 w-full text-left sm:p-4">China</p>
              <p className="title2 hidden w-full p-4 text-left sm:block">
                Hong Kong
              </p>
            </div>
            <div className="flex flex-col items-start justify-between gap-2 sm:flex-row ">
              <div className="w-full ">
                {[
                  {
                    icon: address,
                    content:
                      "Shenzhen International Trade & Commercial Building Suite No. 17G, Nanhu Road 3009 Shenzhen, China",
                  },
                  {
                    icon: call,
                    content: "Call Us:  +86-755-822-54641",
                  },
                  {
                    icon: fax,
                    content: "Fax:   +86-755-222-06990",
                  },

                  {
                    icon: whatsapp,
                    content: "Chat With Us:   +86-135-0280-7152",
                  },
                  {
                    icon: email,
                    name: " ",
                    content: "Email ID: Sales@duvetltd.com",
                  },
                  {
                    icon: global,
                    name: " ",
                    content: "Website: www.duvetltd.com",
                  },
                ].map(({ name, content, icon }, index) => {
                  return (
                    <div className="my-2 flex items-start gap-4">
                      <img className="p-2" src={icon} alt="" />

                      {index === 5 ? (
                        <p className="my-auto font-outFitRegular  text-base text-mediumGray">
                          {name}
                          <Link to="/">{content}</Link>
                        </p>
                      ) : index === 4 ? (
                        <p className="my-auto font-outFitRegular  text-base text-mediumGray">
                          {name}
                          <a href="mailto:sales@duvetltd.com">{content}</a>
                        </p>
                      ) : index === 1 ? (
                        <p className="my-auto font-outFitRegular  text-base text-mediumGray">
                          {name}
                          <a href="tel:+86-755-822-54641">{content}</a>
                        </p>
                      ) : index === 2 ? (
                        <p className="my-auto font-outFitRegular  text-base text-mediumGray">
                          <a href="fax:+86-755-222-06990">{content}</a>
                        </p>
                      ) : (
                        <p className="my-auto font-outFitRegular  text-base text-mediumGray">
                          {name}
                          {content}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>{" "}
              <p className="title2 w-full text-left sm:hidden">Hong Kong</p>
              <div className="w-full ">
                {[
                  {
                    icon: address,
                    content: `Room 747 7/F, Star House,`,
                    content1:
                      "3 Salisbury Road, Tsim Sha Tsui Kowloon, Hong Kong",
                  },
                  {
                    icon: call,
                    content: "Call Us:   +852-2885-3238",
                  },
                  {
                    icon: fax,
                    content: "Fax: +852-2885-3218",
                  },

                  {
                    icon: whatsapp,
                    content: "Chat With  Us:   +86-135-0280-7152",
                  },
                  {
                    icon: email,
                    content: "Email ID: Sales@duvetltd.com",
                  },
                  {
                    icon: global,
                    content: "Website: www.duvetltd.com",
                  },
                ].map(({ content, icon, content1 }, index) => {
                  return (
                    <div className="my-2 flex items-start gap-4">
                      <img className="p-2" src={icon} alt="" />

                      {index === 5 ? (
                        <p className="my-auto font-outFitRegular  text-base text-mediumGray">
                          <Link to="/">{content}</Link>
                        </p>
                      ) : index === 4 ? (
                        <p className="my-auto font-outFitRegular  text-base text-mediumGray">
                          <a href="mailto:sales@duvetltd.com">{content}</a>
                        </p>
                      ) : index === 1 ? (
                        <p className="my-auto font-outFitRegular  text-base text-mediumGray">
                          <a href="tel:+852-2885-3238">{content}</a>
                        </p>
                      ) : index === 2 ? (
                        <p className="my-auto font-outFitRegular  text-base text-mediumGray">
                          <a href="fax:+86-755-222-06990">{content}</a>
                        </p>
                      ) : (
                        <p className="my-auto font-outFitRegular  text-base text-mediumGray">
                          {content} <br />
                          {content1}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className=" m-auto flex flex-col gap-4 rounded-xl bg-darkGray p-7 font-outFitRegular lg:w-[534px] lg:px-[59px] lg:pb-[59px]  lg:pt-[63px]">
            <div>
              <p className="font-outfitMedium text-base text-white lg:text-[28px]">
                Get Personalized assistance
              </p>
              <p className="font-outfitRegular mt-1 text-sm text-mediumGray lg:mt-2 lg:w-[370px] lg:text-lg">
                Don’t be afraid to reach out. Fill the form below (or) Call Us
                at <a href="tel:+86-755-82254641">+86-755-82254641.</a>
              </p>
            </div>
            <InputBox
              rows={1}
              width="w-full"
              handleChange={handleChange}
              value={params.name}
              label="Your Name"
              name="name"
              error={!!errors.name}
              helperText={errors.name}
            />
            <InputBox
              rows={1}
              width="w-full"
              handleChange={handleChange}
              value={params.email}
              label="Email ID"
              name="email"
              error={!!errors.email}
              helperText={errors.email}
            />

            <div className="">
              <TelephoneInput
                onPhoneNumberChange={onPhoneNumberChange}
                params={params}
                errors={errors}
              />
              <p className="font-satoshiMedium relative left-[15px] top-[5px] text-xs text-red ">
                {errors?.phone}
              </p>
            </div>

            <div>
              <TextArea
                placeholder="Write a message to us"
                name="message"
                rows={3}
                handleChange={handleChange}
                value={params.message}
                error={errors?.message}
                helperText={errors?.message}
                className="border border-[#E7E8ED] hover:border-black"
                readOnly={false}
              />
            </div>

            <CustomButton
              onClick={handleSubmit}
              variant="contained"
              size="large"
              height="56px"
              disabled={loading}
            >
              {loading ? (
                <span className="flex items-center justify-center gap-2">
                  <CircularProgress size="2vh" sx={{ color: "black" }} />
                  <p>Send Message</p>
                </span>
              ) : (
                "Send Message"
              )}
            </CustomButton>
            {submitted ? (
              <p className="text-center font-outFitRegular text-sm text-white">
                Our team will get back to you soon.
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
