import React from "react";

import { Link } from "react-router-dom";
import SmallScreenFooter from "./SmallScreenFooter";

const SocialIcons = () => {
  return (
    <div className="">
      <p className="font-outFitRegular text-base font-[500] leading-[25px] text-darkGray">
        Follow Us On:
      </p>
      <div className="flex gap-4 pt-6 md:gap-4 2xl:gap-8">
        <a href="https://www.instagram.com/duvethome/" target="_blank">
          {" "}
          <img src={"/images/footer/instagram.svg"} alt="" />
        </a>

        <a href="https://www.facebook.com/DUVETHOME1/" target="_blank">
          {" "}
          <img src={"/images/footer/facebook.svg"} alt="" />
        </a>

        <a
          href="https://api.whatsapp.com/send?phone=+8613502807152&text=Hi"
          target="_blank"
        >
          {" "}
          <img src={"/images/footer/whatsapp.svg"} alt="" />
        </a>

        <a href="https://www.pinterest.com/DUVETHOME/" target="_blank">
          {" "}
          <img src={"/images/footer/pinterest.svg"} alt="" />
        </a>

        <a href="https://msng.link/o?duvethome1=wc" target="_blank">
          {" "}
          <img src={"/images/footer/wechat.svg"} alt="" />
        </a>
        <a href="https://www.youtube.com/" target="_blank">
          {" "}
          <img src={"/images/footer/youtube.svg"} alt="" />
        </a>
        <a href="https://www.twitter.com/" target="_blank">
          {" "}
          <img src={"/images/footer/twitter.svg"} alt="" />
        </a>
      </div>
    </div>
  );
};

const productLinks = [
  {
    id: 12,
    name: "Home",
    link: "/",
  },
  {
    id: 1,
    name: "Bedding",
    link: "/bedding",
  },
  {
    id: 2,
    name: "Bath",
    link: "/bath",
  },
  {
    id: 3,
    name: "Window",
    link: "/window",
  },
  {
    id: 4,
    name: "Decor",
    link: "/decor",
  },
  {
    id: 5,
    name: "Clothing",
    link: "/clothing",
  },
];

const quickLinks = [
  {
    id: 100,
    name: "About Us",
    link: "/about-us",
  },
  {
    id: 221,
    name: "Contact Us",
    link: "/contact-us",
  },
];

const Footer = () => {
  return (
    <>
      {/* {typeof window !== "undefined" && window.innerWidth > 769 ? ( */}
      <div className="w-full  bg-lightGray  md:px-[3vw] lg:px-[4vw] 2xl:px-[6vw]">
        <div className="flex w-full  py-10 md:gap-6 lg:gap-10  2xl:gap-24 ">
          <div className="w-2/12">
            <img
              src={"/images/duvetNewLogo.svg"}
              className="mt-1 w-[80%]"
              alt=""
            />
            <p className="mt-3 font-outFitMedium text-base leading-normal text-[#222222]">
              Ensuring unwavering trust.
            </p>
          </div>
          <div className="w-9/12">
            <div className="pb-4">
              <div className="flex gap-4 md:gap-4 lg:gap-4 2xl:gap-10">
                {React.Children.toArray(
                  productLinks.map((item) => (
                    <Link to={item.link}>
                      <p className="cursor-pointer font-outFitMedium text-base uppercase text-darkGray">
                        {item.name}
                      </p>
                    </Link>
                  ))
                )}
                {React.Children.toArray(
                  quickLinks.map((item) => (
                    <Link to={item.link}>
                      <p className="cursor-pointer font-outFitMedium text-base uppercase text-darkGray">
                        {item.name}
                      </p>
                    </Link>
                  ))
                )}
              </div>
            </div>

            <hr className="w-full pb-4 text-shadeGray" />
            <div className="flex w-full justify-between ">
              <div className="flex w-[30%] items-center  gap-4">
                <div className="h-[83px] w-[84px]">
                  <img
                    src="/images/wechat.jpeg"
                    alt="qr-code"
                    className="h-full w-full"
                  />
                </div>

                <p className="mt-2 font-outFitMedium text-sm capitalize text-mediumGray">
                  Scan QR code to add us <br /> on wechat
                </p>
              </div>
              <div>
                {" "}
                <SocialIcons />
              </div>
              <div>
                <p className="text-center font-outFitMedium text-sm capitalize text-mediumGray">
                  {" "}
                  © 2024 Duvet Home.
                </p>
                <p className="mt-3 text-center font-outFitMedium text-sm capitalize text-mediumGray">
                  {" "}
                  All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
