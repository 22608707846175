import React, { useEffect } from "react";
import gsap from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const Animation = () => {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    const elements = [
      ".What_we_do1",
      ".What_we_do2",
      ".What_we_do3",
      ".What_we_do4",
      ".What_we_do5",
      ".What_we_do7",
      ".What_we_do8",
    ];

    const animations = elements.map((element, index) => {
      return gsap.to(element, {
        x: [1, 4, 5].includes(index)
          ? "-120%"
          : [2, 3, 6].includes(index)
          ? "120%"
          : "0%",

        y: [0, 1, 2].includes(index)
          ? "-230%"
          : [3, 4].includes(index)
          ? "-115%"
          : "0%",
        opacity: 1,
        duration: 2,
        paused: true,
      });
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".What_we_do_sec",
        start: "top bottom",
        end: "bottom bottom",
     
        scrub: 0.1,
        onEnter: () => {
          animations.forEach((animation) => animation.play());
        },
        onUpdate: (self) => {
          if (self.direction <= 0 && self.progress <= 0.4) {
            animations.forEach((animation) => animation.reverse());
          }
          if (self.direction > 0) {
            animations.forEach((animation) => animation.play());
          }
        },
      },
    });
  }, []);

  return (
    <>
      <section className="What_we_do_sec relative flex md:hidden h-[45vh] flex-col items-center justify-end  ">
        <div className="What_we_do1 absolute z-[60] flex h-[13vh] w-[25vw] items-center justify-center rounded-3xl bg-darkGray">
          <div className="flex flex-col gap-4">
            <img
              className="m-auto w-10"
              src="/images/designing.svg"
              alt="Design"
            />
            <p className="body6 text-white">Design</p>
          </div>
        </div>

        <div className="What_we_do2 absolute z-[60] flex h-[13vh] w-[25vw] items-center justify-center rounded-3xl bg-darkGray">
          <div className="flex flex-col gap-4">
            <img
              className="m-auto w-10"
              src="/images/delivery.svg"
              alt="Logistics"
            />
            <p className="body6 text-white">Logistics</p>
          </div>
        </div>

        <div className="What_we_do3 absolute z-[60] flex h-[13vh] w-[25vw] items-center justify-center rounded-3xl bg-darkGray">
          <div className="flex flex-col gap-4">
            <img
              className="m-auto w-10"
              src="/images/marketing.svg"
              alt="Marketing"
            />
            <p className="body6 text-white">Marketing</p>
          </div>
        </div>

        <div className="What_we_do4 absolute z-[60] flex h-[13vh] w-[25vw] items-center justify-center rounded-3xl bg-darkGray">
          <div className="flex flex-col gap-4">
            <img
              className="m-auto w-10"
              src="/images/sourcing.svg"
              alt="delivery"
            />
            <p className="body6 text-white">Sourcing</p>
          </div>
        </div>

        <div className="What_we_do5 absolute z-[60] flex h-[13vh] w-[25vw] items-center justify-center rounded-3xl bg-darkGray">
          <div className="flex flex-col gap-4">
            {" "}
            <img className="m-auto w-10" src="/images/sales.svg" alt="Sales" />
            <p className="body6 text-white">Sales</p>
          </div>
        </div>

        <div className="What_we_do7 absolute z-[60] flex h-[13vh] w-[25vw] items-center justify-center rounded-3xl bg-darkGray">
          <div className="flex flex-col gap-4">
            <img
              className="m-auto w-10"
              src="/images/merchandising.svg"
              alt="Merchandising"
            />
            <p className="body6 text-white">Merchandising</p>
          </div>
        </div>

        <div className="What_we_do8 absolute z-[60] flex h-[13vh] w-[25vw] items-center justify-center rounded-3xl bg-darkGray">
          <div className="flex flex-col gap-4">
            <img
              className="m-auto w-10"
              src="/images/prod_dev.svg"
              alt="Product development"
            />
            <p className="body6 text-center text-white">Product development</p>
          </div>
        </div>

        <div className="What_we_do9 absolute bottom-0 z-[100] flex h-[28vh] w-[25vw] items-center justify-center rounded-3xl bg-GhostWhite shadow-3xl">
          <div className="">
            <p className="body1 text-center text-darkGray">DUVET HOME</p>
          </div>
        </div>
      </section>

      <div className="h-[5vh]"> </div>
    </>
  );
};

export default Animation;
