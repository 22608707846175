import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import axiosInstance from "../../helpers/axios";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";
import gsap from "gsap/dist/gsap";

import updateMeta from "../../helpers/seo-meta";
import Test from "./BannerImagesSlide";

const NewHome = () => {
  const containerRef = useRef(null);
  const testimonialRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [testimonials, setTestimonials] = useState([{}, {}, {}, {}, {}]);
  const mainRef = useRef(null);
  const [buttonDisable, setButtonDisable] = useState(0);
  const [testimonailArrowButton, setTestimonialArrowButton] = useState(0);

  const fetchTestimonials = () => {
    axiosInstance
      .get(`testimonials`)
      .then((res) => {
        setTestimonials(res?.data?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);
  }, []);

  useEffect(() => {
    let ctx = gsap.context(() => {
      // category

      gsap.set([".interior", ".clothing"], {
        opacity: 0,
      });

      var categoryTL = gsap.timeline({
        scrollTrigger: {
          trigger: ".category",
          start: "top bottom",
          end: "top 12%",
          id: "category view",
          scrub: 2,
          immediateRender: false,
          invalidateOnRefresh: true,
          direction: 1,
          once: true,
          onUpdate: (self) => {
            if (self.progress >= 0.95) {
              gsap.set([".interior", ".clothing"], {
                opacity: 1,
              });
            }

            if (self.progress >= 0.95) {
              gsap.to(".categoryIndicators", {
                autoAlpha: 1,
              });
            }
          },
        },
      });

      categoryTL.to([".bedding", ".bath", ".interior", ".clothing"], {
        x: (index, target) =>
          index === 0
            ? "-110.5%"
            : index === 1
            ? "110.5%"
            : index === 2
            ? "221.5%"
            : "333.5%",

        ease: "power3.inOut",
      });

      //products
      gsap.set(".panel", {
        zIndex: (i, target, targets) => targets.length - i,
      });

      const reductionAmount = innerHeight * 0.12;

      const newInnerHeight = innerHeight - reductionAmount;

      let images = gsap.utils.toArray(".panel:not(.orange)");
      let stagger = 1 / images.length;
      let productImagesTL = gsap.timeline({
        scrollTrigger: {
          trigger: ".productImages",
          start: "top 16%",
          end: () => "+=" + images.length * newInnerHeight,

          pin: true,

          snap: {
            snapTo: 1 / 4,

            // ease: "power3.inOut",
          },
          // duration: 0,
          invalidateOnRefresh: true,
          scrub: 0.2,
          onUpdate: (scrollTrigger) => {
            const scrollPosition = scrollTrigger.progress * images.length;

            if (scrollPosition <= 0.5) {
              setActiveSlide(0);
            }
            if (scrollPosition > 0.5 && scrollPosition <= 1) {
              setActiveSlide(1);
            }
            if (scrollPosition > 1.5 && scrollPosition <= 2) {
              setActiveSlide(2);
            }
            if (scrollPosition > 2.5 && scrollPosition <= 3) {
              setActiveSlide(3);
            }
            if (scrollPosition > 3.5 && scrollPosition <= 4) {
              setActiveSlide(4);
            }
          },
        },
      });

      productImagesTL.fromTo(
        images,
        {
          autoAlpha: 1,
        },
        {
          autoAlpha: 0,
          ease: "power3.inOut",
          duration: stagger,
          stagger: stagger,
        },
        0
      );

      //end
    }, mainRef);

    return () => ctx.revert();
  }, []);
  const handleNextCategory = () => {
    const container = containerRef.current;
    if (container) {
      const slideWidth = container.offsetWidth / 3;
      gsap.to(container, { scrollLeft: "+=" + slideWidth * 3, duration: 1 });
    }

    setButtonDisable((prev) => prev + 1);
  };

  const handleBackCategory = () => {
    const container = containerRef.current;
    if (container) {
      const slideWidth = container.offsetWidth / 3;
      gsap.to(container, { scrollLeft: "-=" + slideWidth * 3, duration: 1 });
    }

    setButtonDisable((prev) => prev - 1);
  };

  const handleNext = () => {
    const container = testimonialRef.current;
    if (container) {
      const slideWidth = container.offsetWidth / 3;
      gsap.to(container, { scrollLeft: "+=" + slideWidth, duration: 0.5 });
    }

    setTestimonialArrowButton((prev) => prev + 1);
  };

  const handleBack = () => {
    const container = testimonialRef.current;
    if (container) {
      const slideWidth = container.offsetWidth / 3;
      gsap.to(container, { scrollLeft: "-=" + slideWidth, duration: 0.5 });
    }

    setTestimonialArrowButton((prev) => prev - 1);
  };

  useEffect(() => {
    document.title = "Home | DuvetHome ";
    updateMeta(
      "description",
      "Duvet Ltd offers premium bedding products for a comfortable and luxurious sleep experience. Shop now for high-quality duvets, pillows, and more."
    );
  }, []);

  return (
    <div className="w-full">
      <div ref={mainRef} className="">
        <div className="h-screen px-1">
          <Test />
        </div>

        <div className="category mt-6 h-[88vh] w-full ">
          <div className="flex h-[20%] w-full flex-col items-center justify-center">
            <div className="mx-auto w-[20rem] pt-2">
              <p className=" text-center font-outFitRegular text-[36px]  font-[600] leading-[3.5rem] text-darkGray">
                Our Categories
              </p>
              <hr className="ml-[11rem] w-20 text-lightBlue" />
            </div>
            <div className="categoryIndicators flex w-full justify-end gap-6 pb-4 pr-4 opacity-0 ">
              <div
                className="cursor-pointer"
                onClick={buttonDisable === 0 ? () => {} : handleBackCategory}
                // onClick={handleBackCategory} disabled={buttonDisable === 0}
              >
                {buttonDisable === 1 ? (
                  <svg
                    width="34"
                    height="24"
                    viewBox="0 0 38 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
                      stroke="#3E94FF"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M35 12H4"
                      stroke="#3E94FF"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="38"
                    height="24"
                    viewBox="0 0 38 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
                      stroke="#A5A5A5"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M35 12H4"
                      stroke="#A5A5A5"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </div>
              <div
                className="cursor-pointer"
                onClick={buttonDisable === 1 ? () => {} : handleNextCategory}
                disabled={buttonDisable === 1}
              >
                {buttonDisable === 0 ? (
                  <svg
                    width="54"
                    height="24"
                    viewBox="0 0 54 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M46.43 5.92969L52.5 11.9997L46.43 18.0697"
                      stroke="#3E94FF"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 12L52 12"
                      stroke="#3E94FF"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="38"
                    height="24"
                    viewBox="0 0 54 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M46.43 5.92969L52.5 11.9997L46.43 18.0697"
                      stroke="#A5A5A5"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 12L52 12"
                      stroke="#A5A5A5"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
          <div className=" flex h-[70%] w-full items-center justify-center ">
            <div
              className="relative mx-auto h-full w-full overflow-hidden"
              ref={containerRef}
            >
              <Link to="/decor">
                <div className="decor absolute left-[35%] z-50 h-full w-[30%] rounded-[3rem] bg-white ">
                  <img
                    src="/images/categories/decor.jpg"
                    alt="decor"
                    className="h-[90%] w-full  object-cover"
                  />
                  <p className="buttons text-center font-outFitRegular text-[28px] font-[400] text-darkGray">
                    {" "}
                    Decor Home
                  </p>
                </div>
              </Link>

              <Link to="/bedding">
                {" "}
                <div className="bedding absolute left-[35%]  h-full w-[30%]     bg-white ">
                  <img
                    src="/images/categories/bedding.jpg"
                    alt="bedding"
                    className="h-[90%] w-full  object-cover"
                  />
                  <p className="buttons text-center font-outFitRegular text-[28px] font-[400] text-darkGray">
                    {" "}
                    Bedding
                  </p>
                </div>
              </Link>

              <Link to="/bath">
                {" "}
                <div className="bath absolute left-[35%]  h-full w-[30%]   bg-white  ">
                  <img
                    src="/images/categories/bath.jpg"
                    alt="bath"
                    className="h-[90%] w-full  object-cover"
                  />
                  <p className="buttons text-center font-outFitRegular text-[28px] font-[400] text-darkGray">
                    {" "}
                    Bath
                  </p>
                </div>
              </Link>

              <Link to="/interior">
                {" "}
                <div className="interior absolute left-[35%]  h-full w-[30%]   bg-white  ">
                  <img
                    src="/images/categories/interior.jpg"
                    alt="interior"
                    className="h-[90%] w-full  object-cover"
                  />
                  <p className="buttons text-center font-outFitRegular text-[28px] font-[400] text-darkGray">
                    {" "}
                    Interior
                  </p>
                </div>
              </Link>

              <Link to="/clothing">
                {" "}
                <div className="clothing absolute left-[35%]  h-full w-[30%]   bg-white  ">
                  <img
                    src="/images/categories/clothing.jpg"
                    alt="clothing"
                    className="h-[90%] w-full  object-cover"
                  />
                  <p className="buttons text-center font-outFitRegular text-[28px] font-[400] text-darkGray">
                    {" "}
                    Clothing
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="indicators mt-4 flex w-full items-center justify-center gap-2">
            <div
              className={`h-[0.3rem]  rounded-[18px] ${
                buttonDisable === 1
                  ? "w-[2.5rem] bg-lightGrayOne"
                  : "w-[3.2rem] bg-lightBlue"
              } `}
            ></div>
            <div
              className={`h-[0.3rem]  rounded-[18px] ${
                buttonDisable === 0
                  ? "w-[2.5rem] bg-lightGrayOne"
                  : "w-[3.2rem] bg-lightBlue"
              } `}
            ></div>
          </div>
        </div>

        <div className="aboutus h-[88vh] w-full py-2">
          <div className=" h-[35%] w-full ">
            <div className="mx-auto  w-[300px]">
              <p className=" text-center font-outFitRegular text-[36px]  font-[600] leading-[3.5rem] text-darkGray">
                About Us
              </p>
              <hr className="ml-[130px] w-20 text-lightBlue" />
            </div>

            <div className=" mx-auto mt-6 w-[80%] text-justify">
              <p className="text-center font-outFitRegular text-[22px] font-[500] leading-[28px] text-mediumGray">
                DUVET HOME - Designs, Manufactures & Exports stylish trendy &
                modern Home Textile & Garment products to buyers across the
                World. We are a highly experienced sourcing and surplus chain
                management organization That certifies to deliver and serve high
                quality products.
              </p>
            </div>
          </div>

          <div className="aboutUsImage mx-auto h-[60%] w-[80%] ">
            <img
              src="/images/about_us_jpeg.jpg"
              alt="aboutus"
              className="h-full w-full  object-cover"
            />
          </div>
        </div>

        {/* product */}

        <div className="mt-12 w-full px-[4vw]">
          <div className="grid h-full w-full grid-cols-[40vw_minmax(40vw,_1fr)_0px]">
            <div className="z-0 h-[440vh] w-full">
              <div className="flex h-[84vh] w-full items-center justify-center">
                <div className=" text">
                  <p className="font-outFitRegular text-[80px] font-[700] leading-[54px] text-orangeBrown">
                    BEDDING
                  </p>
                  <div className="mt-12 flex gap-3">
                    {React.Children.toArray(
                      [
                        "Comforter Sets",
                        "Duvet Sets",
                        "Quilt Sets",
                        "Bed In a Bag",
                      ].map((item, index) => (
                        <div
                          className="flex items-center justify-center gap-1 "
                          key={index}
                        >
                          <div className="mt-1 h-2 w-2 rounded-full bg-shadeGray"></div>
                          <p className="font-outFitRegular text-[20px] font-[500]">
                            {item}
                          </p>
                        </div>
                      ))
                    )}
                  </div>

                  <div className="mt-2 flex gap-3">
                    {React.Children.toArray(
                      [
                        "Sheets",
                        "Bed Mattressess",
                        "Kids Sheets",
                        "Blankets",
                        "Pillows",
                      ].map((item, index) => (
                        <div
                          className="flex items-center justify-center gap-1 "
                          key={index}
                        >
                          <div className="mt-1 h-2 w-2 rounded-full bg-shadeGray"></div>
                          <p className="font-outFitRegular text-[20px] font-[500]">
                            {item}
                          </p>
                        </div>
                      ))
                    )}
                  </div>

                  <div className="mt-10">
                    <p className="font-outFitRegular text-[18px]  font-[400] leading-[29px] text-lightBlack">
                      Experience a realm of comfort and style with our premium
                      soft essentials. Made from high-quality cotton and
                      hypoallergenic microfiber, experience the perfect blend of
                      coziness and design for a dreamy sleep.
                    </p>
                  </div>

                  <Link to="/bedding">
                    {" "}
                    <div className="mt-6  flex items-center gap-2 font-outFitRegular text-[18px] font-[600] text-[#3E94FF]">
                      {" "}
                      Explore More{" "}
                      <svg
                        width="21"
                        height="16"
                        viewBox="0 0 21 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mt-1"
                      >
                        <path
                          d="M20.7071 8.7071C21.0976 8.31658 21.0976 7.68342 20.7071 7.29289L14.3431 0.928931C13.9526 0.538407 13.3195 0.538407 12.9289 0.928931C12.5384 1.31946 12.5384 1.95262 12.9289 2.34314L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.7071ZM8.74228e-08 9L20 9L20 7L-8.74228e-08 7L8.74228e-08 9Z"
                          fill="#3E94FF"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="flex h-[88vh] w-full items-center justify-center">
                <div className=" text">
                  <p className="font-outFitRegular text-[80px] font-[700] leading-[54px] text-shadeBlue">
                    BATH
                  </p>

                  <div className="mt-12 flex gap-3">
                    {React.Children.toArray(
                      ["Mats", "Bathroom Accessories", "Towels"].map(
                        (item, index) => (
                          <div
                            className="flex items-center justify-center gap-1 "
                            key={index}
                          >
                            <div className="mt-1 h-2 w-2 rounded-full bg-shadeGray"></div>
                            <p className="font-outFitRegular text-[20px] font-[500]">
                              {item}
                            </p>
                          </div>
                        )
                      )
                    )}
                  </div>

                  <div className="mt-2 flex gap-3">
                    {React.Children.toArray(
                      ["Shower Curtains", "Beach Towels"].map((item, index) => (
                        <div
                          className="flex items-center justify-center gap-1 "
                          key={index}
                        >
                          <div className="mt-1 h-2 w-2 rounded-full bg-shadeGray"></div>
                          <p className="font-outFitRegular text-[20px] font-[500]">
                            {item}
                          </p>
                        </div>
                      ))
                    )}
                  </div>

                  <div className="mt-10">
                    <p className="font-outFitRegular text-[18px]  font-[400] leading-[29px] text-lightBlack">
                      Indulge in pure bliss with our exclusive collection of
                      premium bath accessories. Embrace the epitome of luxury,
                      comfort, and durability showcased through a wide array of
                      meticulously crafted designs, all from high-quality
                      materials.
                    </p>
                  </div>

                  <Link to="/bath">
                    {" "}
                    <div className="mt-6  flex items-center gap-2 font-outFitRegular text-[18px] font-[600] text-[#3E94FF]">
                      {" "}
                      Explore More{" "}
                      <svg
                        width="21"
                        height="16"
                        viewBox="0 0 21 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mt-1"
                      >
                        <path
                          d="M20.7071 8.7071C21.0976 8.31658 21.0976 7.68342 20.7071 7.29289L14.3431 0.928931C13.9526 0.538407 13.3195 0.538407 12.9289 0.928931C12.5384 1.31946 12.5384 1.95262 12.9289 2.34314L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.7071ZM8.74228e-08 9L20 9L20 7L-8.74228e-08 7L8.74228e-08 9Z"
                          fill="#3E94FF"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="flex h-[88vh] w-full items-center justify-center">
                <div className=" text">
                  <p className="font-outFitRegular text-[80px] font-[700] leading-[54px] text-greenGray">
                    WINDOW
                  </p>
                  <div className="mt-12 flex gap-3">
                    {React.Children.toArray(
                      ["Single Panels", "Set of 2 panels", "Blackouts"].map(
                        (item, index) => (
                          <div
                            className="flex items-center justify-center gap-1 "
                            key={index}
                          >
                            <div className="mt-1 h-2 w-2 rounded-full bg-shadeGray"></div>
                            <p className="font-outFitRegular text-[20px] font-[500]">
                              {item}
                            </p>
                          </div>
                        )
                      )
                    )}
                  </div>

                  <div className="mt-2 flex gap-3">
                    {React.Children.toArray(
                      ["Sheer Voile", "Valances"].map((item, index) => (
                        <div
                          className="flex items-center justify-center gap-1 "
                          key={index}
                        >
                          <div className="mt-1 h-2 w-2 rounded-full bg-shadeGray"></div>
                          <p className="font-outFitRegular text-[20px] font-[500]">
                            {item}
                          </p>
                        </div>
                      ))
                    )}
                  </div>

                  <div className="mt-10">
                    <p className="font-outFitRegular text-[18px]  font-[400] leading-[29px] text-lightBlack">
                      Transform your living space with opulent window decor.
                      Discover elegance in meticulously crafted pieces, creating
                      a luxurious ambiance. Redefine your space with
                      sophistication and immerse yourself in the splendor of
                      your surroundings.
                    </p>
                  </div>

                  <Link to="/window">
                    {" "}
                    <div className="mt-6  flex items-center gap-2 font-outFitRegular text-[18px] font-[600] text-[#3E94FF]">
                      {" "}
                      Explore More{" "}
                      <svg
                        width="21"
                        height="16"
                        viewBox="0 0 21 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mt-1"
                      >
                        <path
                          d="M20.7071 8.7071C21.0976 8.31658 21.0976 7.68342 20.7071 7.29289L14.3431 0.928931C13.9526 0.538407 13.3195 0.538407 12.9289 0.928931C12.5384 1.31946 12.5384 1.95262 12.9289 2.34314L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.7071ZM8.74228e-08 9L20 9L20 7L-8.74228e-08 7L8.74228e-08 9Z"
                          fill="#3E94FF"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="flex h-[88vh] w-full items-center justify-center">
                <div className=" text">
                  <p className="font-outFitRegular text-[80px] font-[700] leading-[54px] text-shadeBrown">
                    DECOR
                  </p>
                  <div className="mt-12 flex gap-3">
                    {React.Children.toArray(
                      ["Outdoor Style", "Carpets & Rugs", "Furnitures"].map(
                        (item, index) => (
                          <div
                            className="flex items-center justify-center gap-1 "
                            key={index}
                          >
                            <div className="mt-1 h-2 w-2 rounded-full bg-shadeGray"></div>
                            <p className="font-outFitRegular text-[20px] font-[500]">
                              {item}
                            </p>
                          </div>
                        )
                      )
                    )}
                  </div>

                  <div className="mt-2 flex gap-3">
                    {React.Children.toArray(
                      ["Decorative Pillows"].map((item, index) => (
                        <div
                          className="flex items-center justify-center gap-1 "
                          key={index}
                        >
                          <div className="mt-1 h-2 w-2 rounded-full bg-shadeGray"></div>
                          <p className="font-outFitRegular text-[20px] font-[500]">
                            {item}
                          </p>
                        </div>
                      ))
                    )}
                  </div>

                  <div className="mt-10">
                    <p className="font-outFitRegular text-[18px]  font-[400] leading-[29px] text-lightBlack">
                      Enhance your living space with our exquisite premium decor
                      collection. Experience the epitome of elegance as you
                      adorn your home with our luxurious products, meticulously
                      designed to elevate aesthetics and create an inviting
                      ambiance.
                    </p>
                  </div>

                  <Link to="/decor">
                    {" "}
                    <div className="mt-6  flex items-center gap-2 font-outFitRegular text-[18px] font-[600] text-[#3E94FF]">
                      {" "}
                      Explore More{" "}
                      <svg
                        width="21"
                        height="16"
                        viewBox="0 0 21 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mt-1"
                      >
                        <path
                          d="M20.7071 8.7071C21.0976 8.31658 21.0976 7.68342 20.7071 7.29289L14.3431 0.928931C13.9526 0.538407 13.3195 0.538407 12.9289 0.928931C12.5384 1.31946 12.5384 1.95262 12.9289 2.34314L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.7071ZM8.74228e-08 9L20 9L20 7L-8.74228e-08 7L8.74228e-08 9Z"
                          fill="#3E94FF"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="flex h-[88vh] w-full items-center justify-center">
                {" "}
                <div className=" text">
                  <p className="font-outFitRegular text-[80px] font-[700] leading-[54px] text-oliveGreenShade">
                    CLOTHING
                  </p>
                  <div className="mt-12 flex gap-3">
                    {React.Children.toArray(
                      [
                        "T-Shirt",
                        "Polo T-Shirt",
                        "Collared Shirt",
                        "Tank Tops",
                      ].map((item, index) => (
                        <div
                          className="flex items-center justify-center gap-1 "
                          key={index}
                        >
                          <div className="mt-1 h-2 w-2 rounded-full bg-shadeGray"></div>
                          <p className="font-outFitRegular text-[20px] font-[500]">
                            {item}
                          </p>
                        </div>
                      ))
                    )}
                  </div>

                  <div className="mt-2 flex gap-3">
                    {React.Children.toArray(
                      ["Vests", "Safety Vests", "Shorts", "Sweatpants"].map(
                        (item, index) => (
                          <div
                            className="flex items-center justify-center gap-1 "
                            key={index}
                          >
                            <div className="mt-1 h-2 w-2 rounded-full bg-shadeGray"></div>
                            <p className="font-outFitRegular text-[20px] font-[500]">
                              {item}
                            </p>
                          </div>
                        )
                      )
                    )}
                  </div>

                  <div className="mt-10">
                    <p className="font-outFitRegular text-[18px]  font-[400] leading-[29px] text-lightBlack">
                      Experience sophistication and comfort like never before
                      with our exclusive clothing collection. Each piece is
                      thoughtfully crafted, combining the latest fashion trends,
                      ensuring you look and feel fabulous in every outfit.
                    </p>
                  </div>

                  <Link to="/clothing">
                    {" "}
                    <div className="mt-6  flex items-center gap-2 font-outFitRegular text-[18px] font-[600] text-[#3E94FF]">
                      {" "}
                      Explore More{" "}
                      <svg
                        width="21"
                        height="16"
                        viewBox="0 0 21 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mt-1"
                      >
                        <path
                          d="M20.7071 8.7071C21.0976 8.31658 21.0976 7.68342 20.7071 7.29289L14.3431 0.928931C13.9526 0.538407 13.3195 0.538407 12.9289 0.928931C12.5384 1.31946 12.5384 1.95262 12.9289 2.34314L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.7071ZM8.74228e-08 9L20 9L20 7L-8.74228e-08 7L8.74228e-08 9Z"
                          fill="#3E94FF"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="productImages grid h-[88vh] w-full grid-cols-[95%_minmax(5%,_1fr)_0px] ">
              <div className="h-full w-full">
                <div className="relative w-full ">
                  <div className="z-1 absolute  -left-[10%] -top-6 mx-auto w-[20rem]  bg-white">
                    <p className=" text-center font-outFitRegular text-[36px]  font-[600] leading-[3.5rem] text-darkGray">
                      Our Products
                    </p>
                    <hr className="ml-[12rem] w-20 text-lightBlue" />
                  </div>
                </div>

                <div className="panelImages flex h-[88vh] w-full items-center justify-center">
                  <div className="panel blue  absolute z-50 h-[75%] w-[80%]">
                    <img
                      src="/images/productsLarge/bedding.png"
                      alt="bedding1"
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className="panel red  absolute z-50 h-[75%]  w-[80%]">
                    <img
                      src="/images/productsLarge/bath.png"
                      alt="bath1"
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className="panel green  absolute z-50 h-[75%]  w-[80%]">
                    <img
                      src="/images/productsLarge/window.png"
                      alt="window"
                      className="h-full w-full"
                    />
                  </div>
                  <div className="panel purple  absolute z-50 h-[75%]  w-[80%]">
                    <img
                      src="/images/productsLarge/decor.png"
                      alt="decor1"
                      className="h-full w-full"
                    />
                  </div>

                  <div className="panel orange  absolute z-50 h-[75%]  w-[80%]">
                    <img
                      src="/images/productsLarge/clothing.png"
                      alt="cloth"
                      className="h-full w-full"
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full">
                <div className="flex h-[88vh] w-[5%] flex-col items-center justify-center gap-4">
                  <div className="flex flex-col gap-2">
                    {Array.from({ length: 5 }).map((item, index) => (
                      <div
                        key={index}
                        className={`h-6 w-[6px] rounded-[6px] ${
                          index === activeSlide
                            ? "bg-lightBlue duration-1000"
                            : "bg-lightGrayOne duration-1000"
                        } `}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Testimonials */}

        <div className="testimonial  h-[80vh] w-full  items-center  justify-center">
          <div className="ourCategoryText  flex  flex-col items-center justify-center">
            <p className=" text-center font-outFitRegular text-[36px]  font-[600] leading-[3.5rem] text-darkGray">
              What Our Customers Say
            </p>
            <hr className="ml-10 w-20 text-lightBlue" />
          </div>
          <div className="flex w-full justify-end gap-6 pr-10">
            <div
              className="cursor-pointer"
              onClick={testimonailArrowButton === 0 ? () => {} : handleBack}
            >
              {testimonailArrowButton >= 1 ? (
                <svg
                  width="34"
                  height="24"
                  viewBox="0 0 38 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
                    stroke="#3E94FF"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M35 12H4"
                    stroke="#3E94FF"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="38"
                  height="24"
                  viewBox="0 0 38 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
                    stroke="#A5A5A5"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M35 12H4"
                    stroke="#A5A5A5"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </div>
            <div
              className="cursor-pointer"
              onClick={
                testimonailArrowButton === testimonials?.length - 3
                  ? () => {}
                  : handleNext
              }
            >
              {testimonailArrowButton >= 0 &&
              testimonailArrowButton < testimonials.length - 3 ? (
                <svg
                  width="54"
                  height="24"
                  viewBox="0 0 54 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M46.43 5.92969L52.5 11.9997L46.43 18.0697"
                    stroke="#3E94FF"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 12L52 12"
                    stroke="#3E94FF"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="38"
                  height="24"
                  viewBox="0 0 54 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M46.43 5.92969L52.5 11.9997L46.43 18.0697"
                    stroke="#A5A5A5"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 12L52 12"
                    stroke="#A5A5A5"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>

          <div
            className="mx-auto  flex h-[60vh] w-[63vw] items-center overflow-hidden   py-4 "
            ref={testimonialRef}
          >
            {testimonials &&
              React.Children.toArray(
                testimonials.map((item) => (
                  <div className="scroll-item cardCont relative flex h-full w-1/3 flex-none items-center">
                    <div className="cardBack absolute flex  h-[90%] w-[90%] flex-col items-center justify-center rounded-3xl  bg-darkGray px-10">
                      <div className="flex gap-3">
                        {Array.from(
                          { length: item?.rating },
                          (star, starIndex) => (
                            <img
                              key={starIndex}
                              src={"/images/star.svg"}
                              alt="rating"
                              className="w-6"
                            />
                          )
                        )}
                      </div>
                      <div className="mt-10">
                        <p className="font-outFitRegular text-[1rem] font-[400]  leading-[20px]  text-white">
                          {item?.content}
                        </p>
                      </div>

                      <hr className="mt-6 w-[54px] text-white" />

                      <p className="mt-10 text-center font-outFitRegular text-[1rem] font-[300] leading-[25px]  text-white">
                        {item?.name}
                      </p>
                    </div>
                  </div>
                ))
              )}
          </div>

          <div className="indicators mt-[3vh] flex w-full items-center justify-center gap-2">
            {Array.from({ length: testimonials?.length - 2 }).map(
              (_, index) => (
                <div
                  key={index}
                  className={`h-[0.3rem] rounded-[18px] ${
                    testimonailArrowButton === index
                      ? "w-[3.2rem] bg-lightBlue duration-1000"
                      : "w-[2.5rem] bg-lightGrayOne duration-1000"
                  }`}
                ></div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHome;
