import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    currency: "INR",
    items: [],
    coupon: {},
    total: {},
    whatsapp_number:  "+916364721251",
    show_banner:false,
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        updateCart: (state, action) => {
            state.items = action.payload.data.items;
            state.coupon = action.payload.data.coupon;
            state.total = action.payload.data.total;
            state.currency = action.payload.data.currency;
            state.whatsapp_number = action.payload.data.whatsapp_number;
            state.show_banner = action.payload.data.show_banner;
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateCart } = cartSlice.actions

export default cartSlice.reducer