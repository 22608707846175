import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import React from 'react';

const TelephoneInput = ({ params, onPhoneNumberChange, errors }) => {

    let defaultStyles = 'border border-[#E7E8ED] text-[#3C567E]'
    let errorStyles = 'border border-[#dc2626] '

    return (
        <div className='w-full'>
            <IntlTelInput
                containerClassName='intl-tel-input w-full'
                inputClassName={`p-[14px] py-4 px-1 outline-[#74BFB6] w-full rounded-md ${errors.phone ? errorStyles : defaultStyles} text-[16px] font-arial`}
                fieldId='telephone'
                fieldName='phone'
                nationalMode={false}
                value={params.phone} 
                formatOnInit={true}
                autoComplete='true'
                preferredCountries={['us', 'in', 'ae', 'ca']}
                onPhoneNumberChange={onPhoneNumberChange}
                onPhoneNumberBlur={onPhoneNumberChange}
                useMobileFullscreenDropdown={false}
                format={true}
            />
        </div>
    )
}


export default TelephoneInput