/* eslint-disable react/no-direct-mutation-state */
import gsap from "gsap/dist/gsap";
import { useEffect } from "react";

import React, { useState, useRef } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import axiosInstance from "../../helpers/axios";
import Typography from "@mui/material/Typography";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import { headersMobile } from "./helpers";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, styleProps }) => ({
  boxShadow: "none",
  "& .MuiAccordionSummary-root": {
    minHeight: 64,

    backgroundColor: styleProps.expanded ? "#F6F6F6" : "transparent",
    "&.Mui-expanded": {
      marginBottom: 0,
      outline: "none",
    },
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#222222" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  borderRadius: 9,
  minHeight: 64,
  padding: 0,
  border: "none",
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginRight: theme.spacing(4),
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: theme.spacing(2),
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(270deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

const HeaderSmallScreens = () => {
  const [open, setOpen] = useState(false);
  const modalRef = useRef(null);

  const navigate = useNavigate();
  let { pathname } = useLocation();

  const toggleModal = () => {
    setOpen(!open);
    if (!open) {
      gsap.to(modalRef.current, {
        y: 0,
        duration: 0.01,
        ease: "power2.out",
        zIndex: 0,
      });
    } else {
      gsap.to(modalRef.current, {
        y: "-100%",
        duration: 0.01,
        ease: "power2.in",
        zIndex: 0,
      });
    }
  };

  const handleClose = () => {
    setExpanded(-1);
    setOpen(false);
    gsap.to(modalRef.current, {
      y: "-100%",
      duration: 0.01,
      ease: "power2.in",
      zIndex: 0,
    });
  };

  const [expanded, setExpanded] = React.useState(-1);
  const [menus, setMenus] = React.useState(headersMobile);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const fetchSubCategories = () => {
    axiosInstance
      .get(`/all-categories`)
      .then((res) => {
        let response = res?.data?.category_data;
        let menulist = [...menus];

        response.map(
          (item, index) => (menulist[index]["subList"] = item.child)
        );
        setMenus(menulist);
      })
      .catch((err) => {});
  };

  React.useEffect(() => {
    fetchSubCategories();
    setOpen(false);
  }, []);

  return (
    <div className="sticky top-0 z-[999] h-[8vh]  w-full">
      <div className="header  w-full">
        <div className="smallScreen absolute z-50 flex h-[10vh] w-full items-center justify-between bg-white px-4 shadow-xl">
          <Link to="/">
            {" "}
            <div onClick={handleClose} className="">
              <img
                src={"/images/duvetNewLogo.svg"}
                alt=""
                className="h-full w-[45%]"
              />
            </div>
          </Link>

          <div className="flex items-center gap-4 ">
            <div>
              <div
                className={` menu-toggle flex h-[35px] w-[35px] cursor-pointer flex-col items-center justify-center bg-transparent`}
                onClick={toggleModal}
              >
                <div
                  className={`bar transform-all  h-[1px]  bg-darkGray duration-700 ${
                    open ? " w-[30px] translate-y-1 rotate-45 " : "w-[33px]"
                  }`}
                ></div>

                <div
                  className={`bar transform-all mt-2 h-[1px]  bg-darkGray duration-700 ${
                    open
                      ? "opacity-1 w-[30px] -translate-y-[5px] -rotate-45"
                      : "opacity-1 w-[33px]"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div
          ref={modalRef}
          className={`modal absolute w-full  transform bg-white shadow-xl transition-transform duration-1000 ${
            open ? "translate-y-0 " : "-translate-y-full"
          }`}
        >
          <div className="px-3 pt-[9vh]">
            <div>
              {menus?.map((item, index) => (
                <Accordion
                  key={index}
                  styleProps={{ expanded: expanded === index }}
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>
                      <p
                        className={`${
                          (pathname.includes("/")
                            ? pathname.split("/")[1]
                            : pathname) === item?.name.toLowerCase()
                            ? " font-[700] text-oliveGreen"
                            : "font-outFitRegular text-[16px] font-[700] text-darkGray"
                        } cursor-pointer font-[500]`}
                      >
                        {item?.name}
                      </p>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-col   scroll-auto pl-4">
                      {item?.subList?.map((subList, index) => (
                        <Link
                          key={index}
                          to={`/${item?.name.toLocaleLowerCase()}/${
                            subList?.slug
                          }`}
                          className="py-2 font-outFitRegular text-sm font-[500] text-mediumGray"
                        >
                          <p
                            onClick={toggleModal}
                            className={
                              (pathname.includes("/")
                                ? pathname.split("/")[2]
                                : pathname) === subList?.slug?.toLowerCase()
                                ? "mb-3 translate-x-4  transform cursor-pointer text-sm uppercase text-oliveGreen duration-1000"
                                : "darkGray mb-1 cursor-pointer text-sm uppercase"
                            }
                          >
                            {subList?.name}
                          </p>
                        </Link>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <div className="flex flex-col px-1" onClick={handleClose}>
                <hr className="text-gray" />
                <Link to="/about-us">
                  {" "}
                  <p
                    className={`${
                      (pathname.includes("/")
                        ? pathname.split("/")[1]
                        : pathname) === "about-us"
                        ? "text-oliveGreen"
                        : " text-darkGray"
                    } cursor-pointer py-4 pl-3 font-outFitRegular text-[16px] font-[700]`}
                  >
                    ABOUT US
                  </p>
                </Link>
              </div>
              <div className="flex flex-col px-1" onClick={handleClose}>
                <hr className="text-gray" />
                <Link to="/contact-us">
                  {" "}
                  <p
                    className={`${
                      (pathname.includes("/")
                        ? pathname.split("/")[1]
                        : pathname) === "contact-us"
                        ? "text-oliveGreen"
                        : " text-darkGray"
                    } cursor-pointer py-4 pl-3 font-outFitRegular text-[16px] font-[700]`}
                  >
                    CONTACT US
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSmallScreens;
