import React from "react";
import "./reset.css";
import ReactDOM from "react-dom";
import "./index.css";
import "react-intl-tel-input/dist/main.css";


import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";

// import { StylesProvider } from '@material-ui/core/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

ReactDOM.hydrate(
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
  </BrowserRouter>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
