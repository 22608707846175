export const languages = [
  {
    name: "English",
    value: "English",
  },
  {
    name: "Arabic",
    value: "Arabic",
  },
  {
    name: "Dutch",
    value: "Dutch",
  },
  {
    name: "French",
    value: "French",
  },
  {
    name: "German",
    value: "German",
  },
  {
    name: "Italian",
    value: "Italian",
  },
  {
    name: "Portuguese",
    value: "Portuguese",
  },
  {
    name: "Spanish",
    value: "Spanish",
  },
  {
    name: "Ukrainian",
    value: "Ukrainian",
  },
  {
    name: "Chinese",
    value: "Chinese",
  },
  {
    name: "Russian",
    value: "Russian",
  },
  {
    name: "Hindi",
    value: "Hindi",
  },
];

export const headers = [

  {
    name: "HOME",
    url: "/",
    icon: "",
    active: false,
    dropdown: false,
    subList: [],
  },
 
  {
    name: "BEDDING",
    url: "",
    icon: "",
    active: false,
    dropdown: false,
    subList: [],
  },
  {
    name: "BATH",
    url: "",
    icon: "",
    active: false,
    dropdown: false,
    subList: [],
  },
  {
    name: "WINDOW",
    url: "",
    icon: "",
    active: false,
    dropdown: false,
    subList: [],
  },
  {
    name: "DECOR",
    url: "",
    icon: "",
    active: false,
    dropdown: false,
    subList: [],
  },
  {
    name: "CLOTHING",
    url: "",
    icon: "",
    active: false,
    dropdown: false,
    subList: [],
  },
  {
    name: "ABOUT-US",
    url: "about-us",
    icon: "",
    active: false,
    dropdown: false,
    subList: [],
  },

  {
    name: "CONTACT-US",
    url: "contact-us",
    icon: "",
    active: false,
    dropdown: false,
    subList: [],
  },
];


export const headersMobile = [
  {
    name: "BEDDING",
    url: "",
    icon: "",
    active: false,
    subList: [
      {
        name: "Comforter Sets",
        url: "soft-decors",
        icon: "",
        Isactive: false,
      },
      {
        name: "Duvet Sets",
        url: "decors",
        icon: "",
        Isactive: false,
      },
      {
        name: "Quilt Sets",
        url: "decors",
        icon: "",
        Isactive: false,
      },
      {
        name: "Bed in a Bag",
        url: "decors",
        icon: "",
        Isactive: false,
      },
      {
        name: "Sheets",
        url: "/decors",
        icon: "",
        Isactive: false,
      },
      {
        name: "Kids Sheets",
        url: "/decors",
        icon: "",
        Isactive: false,
      },
      {
        name: "Blankets",
        url: "/decors",
        icon: "",
        Isactive: false,
      },
      {
        name: "Pillows",
        url: "/decors",
        icon: "",
        Isactive: false,
      },
      {
        name: "Bed Mattresses",
        url: "/decors",
        icon: "",
        Isactive: false,
      },
    ],
  },
  {
    name: "BATH",
    url: "",
    icon: "",
    active: false,
    subList: [
      {
        name: "Mats",
        url: "",
        icon: "",
        Isactive: false,
      },
      {
        name: "Bathroom Accessories",
        url: "",
        icon: "",
        Isactive: false,
      },
      {
        name: "Shower Curtains",
        url: "",
        icon: "",
        Isactive: false,
      },
      {
        name: "Towels",
        url: "",
        icon: "",
        Isactive: false,
      },
      {
        name: "Beach Towels",
        url: "",
        icon: "",
        Isactive: false,
      },
    ],
  },
  {
    name: "WINDOW",
    url: "",
    icon: "",
    active: false,
    subList: [
      {
        name: "Single Panels",
        url: "",
        icon: "",
      },
      {
        name: "Set of 2 Panels",
        url: "",
        icon: "",
      },
      {
        name: "Blackouts",
        url: "",
        icon: "",
      },
      {
        name: "Sheer Voile",
        url: "",
        icon: "",
      },
      {
        name: "Valences",
        url: "",
        icon: "",
      },
    ],
  },
  {
    name: "DECOR",
    url: "",
    icon: "",
    active: false,
    subList: [
      {
        name: "Decorative Pillows",
        url: "",
        icon: "",
      },
      {
        name: "Carpets & Rugs",
        url: "",
        icon: "",
      },
      {
        name: "Furniture",
        url: "",
        icon: "",
      },
      {
        name: "Outdoor Style",
        url: "",
        icon: "",
      },
    ],
  },
  {
    name: "CLOTHING",
    url: "",
    icon: "",
    active: false,
    subList: [
      {
        name: "T-Shirt",
        url: "",
        icon: "",
      },
      {
        name: "Polo T-Shirt",
        url: "",
        icon: "",
      },
      {
        name: "Collared Shirts",
        url: "",
        icon: "",
      },
      {
        name: "Tank Tops",
        url: "",
        icon: "",
      },
      {
        name: "Vests",
        url: "",
        icon: "",
      },
      {
        name: "Saftey Vests",
        url: "",
        icon: "",
      },
      {
        name: "Sweatpants",
        url: "",
        icon: "",
      },
      {
        name: "Shorts",
        url: "",
        icon: "",
      },
    ],
  },
];
