import React from "react";


const Loader = () => {
  return (
    <div className="flex h-[88vh] w-full items-center justify-center">
      <div className="loader">
        <div className="circle scale"></div>
        <div className="circle scale"></div>
        <div className="circle scale"></div>
        <div className="circle scale"></div>
      </div>
    </div>
  );
};

export default Loader;
