import React from "react";
import classNames from "classnames";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

const countryList = [
  {
    USD: "us",
    INR: "in",
    AED: "ae",
    SAR: "sa",
    EUR: "gb",
  },
];
const Phone = ({
  name,
  placeholder = "",
  value,
  helperText = "",
  error = false,
  handleChange,
  onBlur = null,
  classes = "",
}) => {
  const styleClasses = classNames(
    "base-text",
    "outline-none",
    classes,
    `rounded-lg  p-4 text-gray-900 ${error ? "border border-error " : ""} `
  );
  const currency =
    typeof window !== "undefined"
      ? localStorage.getItem("CURRENCY") || "INR"
      : "INR";
  const dialCode = () => {
    if (currency) {
      let index = countryList.findIndex((x) => x[currency]);
      return index != -1 ? countryList[index][currency] : "us";
    }
    return "in";
  };

  function phoneChange(valid, phone, country) {
    handleChange({
      target: {
        name: "phone",
        value: {
          valid,
          number: phone,
          ...country,
        },
      },
    });
    // handleChange({
    //     target: {
    //         name: 'phone',
    //         value: phone
    //     }
    // });
  }

  return (
    <div className="w-full mb-4" style={{ zIndex: "9999" }}>
      <IntlTelInput
        style={{
          fontFamily: "OutFit Regular",
          width: "100%",
        }}
        value={value.number || ""}
        preferredCountries={["us"]}
        onPhoneNumberChange={phoneChange}
        onPhoneNumberBlur={onBlur}
        inputClassName={styleClasses}
        placeholder={placeholder}
        // ref={(ref) => {
        //   if (ref) {
        //     ref.setFlag(dialCode());
        //   }
        // }}
      />
      {helperText.length > 0 && (
        <p className="relative top-0 py-2 body4 left-3 text-red">
          {helperText}
        </p>
      )}
    </div>
  );
};

export default Phone;
