import * as React from "react";
import { Link } from "react-router-dom";
import { toTitleCase } from "../../helpers/toTitleCase";

const BreadCrumb = ({ links }) => (
  <div role="presentation" className="hidden md:hidden lg:block">
    <p className="body6 text-[#A8A8A8]">
      {links.map((item, i, arr) =>
        i !== arr.length - 1 ? (
          <React.Fragment>
            <Link className="cursor-pointer " to={item?.url}>
              {toTitleCase(item?.path)}
            </Link>
            <span className="px-1 ">{"/"}</span>
          </React.Fragment>
        ) : (
          <a className="text-mediumGray">{toTitleCase(item?.path)}</a>
        )
      )}
    </p>
  </div>
);

export default BreadCrumb;
