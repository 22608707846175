import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { store } from "./store";
import { Provider } from "react-redux";

import ScrollToTop from "./ScrollToTop";

import { FloatingWhatsApp } from "react-floating-whatsapp";
import HeaderSmallScreens from "./pages/Header/HeaderSmallScreens";
import Header from "./pages/Header/Header";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Home from "./pages/Home/Home";
import ProductDetails from "./pages/ProductDetails";
import Products from "./pages/Products";
import Footer from "./pages/Footer/Footer";
import ScrollToTopButton from "./components/ScrollToTopButton";
import PageNotFound from "./pages/PageNotFound";

import SmallScreenFooter from "./pages/Footer/SmallScreenFooter";
import GoogleTranslator from "./pages/Footer/GoogleTranslator";
import Test from "./pages/Footer/DragOfTranslator";
import DragOfTranslator from "./pages/Footer/DragOfTranslator";

function App() {
  const [showWhatsApp, setShowWhatsApp] = useState(false);
  const [isClient, setIsClient] = useState(true);

  useEffect(() => {
    setIsClient(typeof window !== "undefined" && window.innerWidth < 769);
    setShowWhatsApp(true);
  }, []);

  return (
    <div className="app_parent">
      <Provider store={store}>
        <ScrollToTop />
        {/* <ScrollToTopButton /> */}

        <div className="">
          {/* {isClient ? <HeaderSmallScreens /> : <Header />} */}

          <div className="hidden lg:block">
            <Header />
          </div>

          <div className="block lg:hidden">
            <HeaderSmallScreens />
          </div>

          {showWhatsApp ? (
            <FloatingWhatsApp
              phoneNumber="+8613502807152"
              accountName="Duvet Home"
              chatMessage="Hello there! 
             Welcome to Duvet Home. How can we assist you?"
              avatar={"/images/whatsLogo.svg"}
              allowEsc
              allowClickAway
              notification
              notificationSound
            />
          ) : (
            <></>
          )}

          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about-us" exact element={<AboutUs />} />

            <Route path="/contact-us" exact element={<ContactUs />} />
            <Route path="/:category" exact element={<Products />} />
            <Route
              path="/:category/:subcategory"
              exact
              element={<Products />}
            />
            <Route
              path="/:category/:subcategory/:id"
              exact
              element={<ProductDetails />}
            />


            <Route path="*" exact element={<PageNotFound />} />
          </Routes>
          {/* <Footer /> */}

          {/* {isClient ? <SmallScreenFooter /> : <Footer />} */}

          <div className="hidden lg:block">
            <Footer />
          </div>

          <div className="block lg:hidden">
            <SmallScreenFooter />
          </div>

          {/* <GoogleTranslator /> */}
          <DragOfTranslator />
        </div>
      </Provider>
    </div>
  );
}

export default App;
