import React from "react";

const SizePicker = ({ size, is_active }) => {
  return (
    <div
      className={`flex h-full  min-w-[6rem] items-center  justify-center rounded-lg  p-2 font-outFitRegular text-[1rem] font-[500] text-darkGray lg:text-[1.1rem] ${
        is_active ? "border-2 border-oliveGreen" : "border"
      } `}
    >
      {size}
    </div>
  );
};

export default SizePicker;
