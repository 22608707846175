import React from "react";

import { Link } from "react-router-dom";
const productLinks = [
  {
    id: 12,
    name: "Home",
    link: "/",
  },
  {
    id: 1,
    name: "Bedding",
    link: "/beddding",
  },

  {
    id: 2,
    name: "Bath",
    link: "/bath",
  },
  {
    id: 3,
    name: "Window",
    link: "/window",
  },
  {
    id: 4,
    name: "Decor",
    link: "/decor",
  },
  {
    id: 5,
    name: "Clothing",
    link: "/clothing",
  },
  {
    id: 100,
    name: "About Us",
    link: "/about-us",
  },
  {
    id: 221,
    name: "Contact Us",
    link: "/contact-us",
  },
];

const quickLinks = [];

const SmallScreenFooter = () => {
  return (
    <div className="mt-4 w-full rounded-[6px] bg-lightGray py-6 pb-6">
      <div className="flex w-full px-4 md:px-24">
        <img src={"/images/duvetNewLogo.svg"} alt="" className="w-[40%]" />
      </div>
      <p className="mt-4 w-full px-4 font-outFitRegular  text-[14px] font-[400] leading-[20px] text-mediumGray md:px-24">
        Ensuring unwavering trust.
      </p>

      <div className="mb-2 mt-10 grid  grid-cols-2 gap-4 px-4 md:px-24">
        {React.Children.toArray(
          productLinks.map((item) => (
            <Link to={item.link}>
              <p className="cursor-pointer font-outFitMedium text-sm uppercase text-darkGray">
                {item.name}
              </p>
            </Link>
          ))
        )}
      </div>

      <div className="mt-10 flex w-full gap-6  px-4 md:px-24 ">
        <a href="https://www.instagram.com/duvethome/" target="_blank">
          {" "}
          <img
            src={"/images/footer/instagram.svg"}
            alt="instagram"
            className="w-6"
          />
        </a>

        <a href="https://www.facebook.com/DUVETHOME1/" target="_blank">
          {" "}
          <img
            src={"/images/footer/facebook.svg"}
            alt="facebook"
            className="w-6"
          />
        </a>

        <a href="https://www.pinterest.com/DUVETHOME/" target="_blank">
          {" "}
          <img
            src={"/images/footer/pinterest.svg"}
            alt="pinterest"
            className="w-6"
          />
        </a>

        <a href="https://msng.link/o?duvethome1=wc" target="_blank">
          {" "}
          <img src={"/images/footer/wechat.svg"} alt="wechat" className="w-6" />
        </a>
        <a href="https://www.youtube.com/" target="_blank">
          {" "}
          <img
            src={"/images/footer/youtube.svg"}
            alt="youtube"
            className="w-6"
          />
        </a>
        <a href="https://www.twitter.com/" target="_blank">
          {" "}
          <img
            src={"/images/footer/twitter.svg"}
            alt="twitter"
            className="w-6"
          />
        </a>
      </div>
      <hr className="mx-auto mt-6 w-[90%] text-[#B8B8B8] " />

      <div className="mt-6 flex w-full items-center gap-4 px-4 md:px-24">
        <div className="h-[83px] w-[84px]">
        <img
          src="/images/wechat.jpeg"
          alt="qr-code"
          className="w-full h-full"
        />
        </div>
       

        <div className="flex flex-col">
          <p className="text-[14px] font-[400] text-mediumGray">
            Scan QR code to
          </p>
          <p className="text-[14px] font-[400] text-mediumGray">
            Add us on wechat
          </p>
        </div>
      </div>

      <p className="mt-8 w-[200px] px-4 font-outFitRegular text-sm font-[400] text-darkGray md:px-24">
        © 2024 Duvet Home.
      </p>
      <p className="mt-2 w-[200px] px-4 font-outFitRegular text-sm font-[400] text-darkGray md:px-24">
        All Rights Reserved
      </p>
   
    </div>
  );
};

export default SmallScreenFooter;
