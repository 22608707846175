import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";


const BannerImagesSlide = () => {
  const slidesData = [
    {
      id: 1,
      text: "Your Comfort Zone Redefined",
      text2: "Dive into duvet for the ultimate home experience.",
      bgColor: "bg-red",
      image: "/images/background/bg1.jpg",
      position: "left-[5%] top-[40%]",
    },
    {
      id: 2,
      text: "Balance In Duvet Embrace",
      text2: "The harmony of comfort and style you seek.",
      bgColor: "bg-oliveGreen",
      image: "/images/background/bg2.jpg",
      position: "left-[33%] top-[40%]",
    },
    {
      id: 3,
      text: "Your Relaxation, Our Mission",
      text2: "A new dimension of relaxation just for you.",
      bgColor: "bg-red",
      image: "/images/background/bg3.jpg",
      position: "left-[33%] top-[30%]",
    },
    {
      id: 4,
      text: "Experience Duvet Elegance Daily",
      text2: "The ultimate in-home comfort & elegance",
      bgColor: "bg-lightBlue",
      image: "/images/background/bg4.jpg",
      position: "left-[5%] top-[60%]",
    },
    {
      id: 5,
      text: "Luxury Lies In Our Threads",
      text2: "The timeless of duvet craftsmanship you deserve.",
      bgColor: "bg-oliveGreen",
      image: "/images/background/bg5.jpg",
      position: "left-[5%] top-[40%]",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const nextSlideIndex = (currentSlide + 1) % slidesData.length;
    const prevSlideIndex =
      currentSlide === 0 ? slidesData.length - 1 : currentSlide - 1;

    const timeline = gsap.timeline();

    timeline.to(`#slide-${currentSlide}`, {
      x: "-100%",
      opacity: 1,
      duration: 2,
    });

    timeline.fromTo(
      `#slide-${nextSlideIndex}`,
      { x: "100%" },
      { x: "0%", opacity: 1, duration: 2 },
      "<"
    );

    timeline.fromTo(
      `#slide-${prevSlideIndex}`,
      { x: "0%" },
      { x: "-100%", opacity: 0, duration: 2 },
      "<"
    );

    timeline.fromTo(
      `#slide-${currentSlide} .title`,
      { opacity: 1 },
      { opacity: 1, duration: 1 },
      `+=0.2`,
      "<"
    );

    timeline.fromTo(
      `#slide-${nextSlideIndex} .title`,
      { opacity: 0 },
      { opacity: 1, duration: 1 },
      "<"
    );

    timeline.fromTo(
      `#slide-${prevSlideIndex} .title`,
      { opacity: 0 },
      { opacity: 1, duration: 1 },
      "<"
    );

    const timeout = setTimeout(() => {
      setCurrentSlide(nextSlideIndex);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [currentSlide]);

  return (
    <div className="relative mx-auto h-[100vh] w-full overflow-hidden">
      {slidesData.map((slide, index) => (
        <div
          id={`slide-${index}`}
          key={slide.id}
          className={`customslide absolute h-full w-full `}
          style={{
            opacity: index === currentSlide ? 1 : 0,
            transform:
              index === currentSlide ? "translateX(0%)" : "translateX(100%)",
          }}
        >
          <div className="absolute h-full w-full  text-white">
            <div className={`${slide.position} title absolute`}>
              <p className="font-outFitRegular text-5xl">{slide.text}</p>
              <p className="mt-2 font-outFitSemiBold text-xl text-center">{slide?.text2}</p>
            </div>
          </div>
          <img src={slide.image} alt="slide" className="h-full w-full object-cover" />
        </div>
      ))}
    </div>
  );
};

export default BannerImagesSlide;
