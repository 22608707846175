import React, { useEffect, useState } from "react";

import SmallScreenHome from "./SmallScreenHome/SmallScreenHome";
import NewHome from "./NewHome";

const Home = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(true);

  useEffect(() => {
    setIsSmallScreen(typeof window !== "undefined" && window.innerWidth < 769);
  }, []);

  return (
    <>
      {isSmallScreen ? <SmallScreenHome /> : <NewHome />}

      {/* <div className="hidden lg:block">
        <NewHome />
      </div>

      <div className="block lg:hidden">
        <SmallScreenHome />
      </div> */}
    </>
  );
};

export default Home;
